import React from "react";
import accessDeniedImg from "../../images/access_denied_img.png";
import { AccessDeniedPageContainer } from "./AccessDeniedPageContainer";

const AccessDeniedPage = () => {
  return (
    <AccessDeniedPageContainer>
      <div className="logo">
        <img src={accessDeniedImg} alt="accessDeniedImg" />
      </div>
    </AccessDeniedPageContainer>
  );
};

export default AccessDeniedPage;
