import axios from 'axios';
import { getToken,getApiToken, getBranchToken } from './get-token';

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
       "Access-Control-Allow-Origin": "*",
       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    };
    config.params = {
      ...config.params,
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
