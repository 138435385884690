import { ActionTypes } from '../constants/ActionTypes';
import http from "../../utils/http";
import { API_ENDPOINTS } from '../../utils/api-endpoints';
import { getToken,getCompanyId,getBranchId } from '../../utils/get-token';
import { ReactProjectBaseUrl } from '../../utils/Constants'
import Cookies from 'js-cookie';

export const setGeoLocationFlag = (flag) =>  ( dispatch,getState) => {
  
    let GeoLocationFlag  = flag
    Cookies.set('GeoLocationFlag',GeoLocationFlag)
  
  dispatch({ type : ActionTypes.SET_LOCATION_FETCHED , payload : GeoLocationFlag});
};

export const setCategoriesPageCount = (count) =>  ( dispatch,getState) => {
  dispatch({ type : ActionTypes.SET_CATEGORY_COUNTER , payload : count});
};