export const ActionTypes = {
  // SET_TOP_CATEGORIES_PRODUCTS : 'SET_TOP_CATEGORIES_PRODUCTS',
  SET_BESTSELLING_PRODUCTS: "SET_BESTSELLING_PRODUCTS",
  SET_TRENDING_PRODUCTS: "SET_TRENDING_PRODUCTS",
  SET_PRODUCTS: "SET_PRODUCTS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  SET_CATEGORIES: "SET_CATEGORIES",
  ADD_TO_CART: "ADD_TO_CART",
  ADD_QTY_INTO_CART: "ADD_QTY_INTO_CART",
  REMOVE_QTY_FROM_CART: "REMOVE_QTY_FROM_CART",
  REMOVE_TO_CART: "REMOVE_TO_CART",
  CLEAR_CART: "CLEAR_CART",
  SET_ALL_CATEGORIES: "SET_ALL_CATEGORIES",
  SET_PRODUCT: "SET_PRODUCT",
  SET_ALL_PRODUCT: "SET_ALL_PRODUCT",
  SET_USER_MOBILE_NO: "SET_USER_MOBILE_NO",
  SET_ALL_SLIDERS: "SET_ALL_SLIDERS",
  SET_SLIDER: "SET_SLIDER",
  SET_COMPANY_DETAILS_FROM_DOMAIN: "SET_COMPANY_DETAILS_FROM_DOMAIN",
  SET_THEME_SETTINGS: "SET_THEME_SETTINGS",
  SET_CART_ITEMS_AGAIN: "SET_CART_ITEMS_AGAIN",
  SET_BANNERS: "SET_BANNERS",
  SET_TOP_CATEGORIES: "SET_TOP_CATEGORIES",
  SET_LOCATION_FETCHED: "SET_LOCATION_FETCHED",
  SET_SOCIAL_MEDIA_LINK: "SET_SOCIAL_MEDIA_LINK",
  SET_CATEGORY_COUNTER: "SET_CATEGORY_COUNTER",
  SET_CATEGORY_TOTAL_COUNT: "SET_CATEGORY_TOTAL_COUNT",
  SET_BRAND_COUNTER: "SET_BRAND_COUNTER",
  SET_BRAND_TOTAL_COUNT: "SET_BRAND_TOTAL_COUNT",
  SET_BRAND_PAGE_COUNT: "SET_BRAND_PAGE_COUNT",
  SET_BRAND: "SET_BRAND",
  SET_TOTAL_PRODUCT: "SET_TOTAL_PRODUCTS",
  GET_ADDRESS: "GET_ADDRESS",
  SET_BRANDS_FILTER: "SET_BRANDS_FILTER",
  SET_CATEGORY_FILTER: "SET_CATEGORY_FILTER",
  LOG_OUT: "LOG_OUT",
  GET_SALES_LIST: "GET_SALES_LIST",
  GET_SALES_DETAILS: "GET_SALES_DETAILS",
  CANCEL_ORDER: "CANCEL_ORDER",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  SET_RETALED_PRODUCTS: "SET_RETALED_PRODUCTS",
  SET_MIN_MAX_PRICE_RANGE: "SET_MIN_MAX_PRICE_RANGE",
  SET_INCLUE_OUT_OF_STOCK: "SET_INCLUE_OUT_OF_STOCK",
  ADD_TO_CART_FOR_BUY_NOW: "ADD_TO_CART_FOR_BUY_NOW",
  CLEAR_CART_FOR_BUY_NOW: "CLEAR_CART_FOR_BUY_NOW",
  SET_LOGO: "SET_LOGO",
  SET_ALGOLIA_INDEX_NAME: "SET_ALGOLIA_INDEX_NAME",
  GET_SALES_TRACKING_TRAIL: "GET_SALES_TRACKING_TRAIL",
  SET_IS_ONLINE_ORDER_CONFIRMATION_FLOW:
    "SET_IS_ONLINE_ORDER_CONFIRMATION_FLOW",
  SET_DECIMAL_POINTS: "SET_DECIMAL_POINTS",
  FETCH_WISHLIST: "FETCH_WISHLIST",
  AVAILABLE_QUANTITY: "AVAILABLE_QUANTITY",
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  REMOVE_TO_WISHLIST: "REMOVE_TO_WISHLIST",
  CLEAR_WISHLIST: "CLEAR_WISHLIST",
  ENABLE_WISHLIST: "ENABLE_WISHLIST",

  RESET_SELECTED_PRODUCT: "RESET_SELECTED_PRODUCT",

  // profile Details
  FETCH_PROFILE_DETAILS: "FETCH_PROFILE_DETAILS",

  // reset products for shoppage.

  RESET_PRODUCTS: "RESET_PRODUCTS",
  RESET_TOTAL_PRODUCTS: "RESET_TOTAL_PRODUCTS",

  // reset order list
  RESET_ORDER_LIST: "RESET_ORDER_LIST",

  SET_ADVERTISEMENT: "SET_ADVERTISEMENT",
  START_LOADING: "START_LOADING",
  FINISH_LOADING: "FINISH_LOADING",
  GET_FAVICON_LOADER: "FAVICON_LOADER",
};
