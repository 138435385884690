import { Link } from "react-router-dom";
import errorImg from "../../images/error_404.jpg";
import { Button } from "@mui/material";
import { Component } from "react";

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error or send it to an error tracking service
    console.error(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    // Update Error state for route changes.
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
            marginBottom: "2%",
            backgroundColor: "white",
          }}
        >
          <img src={errorImg} alt="error_image" />

          <div className="message-box">
            <div className="buttons-con">
              <div className="action-link-wrap">
                {this.props.location !== "/" && (
                  <Button
                    style={{ backgroundColor: "#17c1e8", borderRadius: "5px" }}
                  >
                    <Link style={{ color: "white" }} to="/">
                      Go to Home Page
                    </Link>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundry;
