import  {ActionTypes}  from "../constants/ActionTypes";
const intialState =[];
export const productbrandfilter = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_BRANDS_FILTER:
          return payload;
        default:
            return state;
    }
    
}

export const productcategoryfilter = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CATEGORY_FILTER:
          return payload;
        default:
            return state;
    }
}