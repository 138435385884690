import { ActionTypes } from "../constants/ActionTypes";
import http from "../../utils/http";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { ReactProjectBaseUrl } from "../../utils/Constants";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../../utils/get-token";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";

export const getTopCategory = () => async (dispatch) => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();

  const response = await axiosFunction(
    token,
    ReactProjectBaseUrl + API_ENDPOINTS.CATEGORIES,
    "POST"
  );

  const categoryResposne = response.data;
  let categoryList = [];

  if (categoryResposne) {
    if (categoryResposne.status) {
      categoryList = categoryResposne.response;
    }
  }

  dispatch({ type: ActionTypes.SET_TOP_CATEGORIES, payload: categoryList });
};
