import { ActionTypes } from "../constants/ActionTypes";
import http from "../../utils/http";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  ReactProjectBaseUrl,
  ADD_TO_CART_LIMIT,
  TOASTER_MESSAGES,
} from "../../utils/Constants";
import Cookies from "js-cookie";
import { GetWishlist } from "./WishlistActions";
import Swal from "sweetalert2";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../../utils/get-token";
import axios from "axios";

// Best seller products
export const fetchBestSellingProducts = () => async (dispatch) => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();

  const response = await axiosFunction(
    token,
    ReactProjectBaseUrl + API_ENDPOINTS.BEST_SELLER_PRODUCTS,
    "POST"
  );

  const bestSellingProductsResponse = response.data;

  let bestSellingProducts = [];

  if (bestSellingProductsResponse) {
    if (bestSellingProductsResponse.status) {
      bestSellingProducts = bestSellingProductsResponse.response;
    }
  }

  dispatch({
    type: ActionTypes.SET_BESTSELLING_PRODUCTS,
    payload: bestSellingProducts,
  });
};

// Trending Products
export const fetchTrendingProducts = () => async (dispatch) => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();

  const response = await axiosFunction(
    token,
    ReactProjectBaseUrl + API_ENDPOINTS.TRENDING_PRODUCTS,
    "POST"
  );

  const trendingProductsResponse = response.data;

  let trendingProducts = [];

  if (trendingProductsResponse) {
    if (trendingProductsResponse.status) {
      trendingProducts = trendingProductsResponse.response;
    }
  }

  dispatch({
    type: ActionTypes.SET_TRENDING_PRODUCTS,
    payload: trendingProducts,
  });
};

export const fetchCategoriesProducts = () => async (dispatch) => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();

  const response = await axiosFunction(
    token,
    ReactProjectBaseUrl + API_ENDPOINTS.CATEGORIES,
    "POST"
  );

  const topCategoriesListResponse = response.data;

  let topCategoriesList = [];

  if (topCategoriesListResponse) {
    if (topCategoriesListResponse.status) {
      topCategoriesList = topCategoriesListResponse.response;
    }
  }

  dispatch({
    type: ActionTypes.SET_TOP_CATEGORIES_PRODUCTS,
    payload: topCategoriesList,
  });
};

export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const selectedProduct =
  (productId, inComingProductVarientId = "") =>
  async (dispatch) => {
    const token =
      isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();
    if (productId !== undefined) {
      const userId = Cookies.get("ID");
      let response = "";
      const demoSelectedProduct = {
        response: {
          productdetalils: [],
          productVarient: [],
          productImages: [],
          productAttributes: [],
        },
      };
      if (productId === 0) {
        response = demoSelectedProduct;
      } else {
        const result = await axiosFunction(
          token,
          `${ReactProjectBaseUrl}${API_ENDPOINTS.FETCH_PRODUCT_DETAIS_BY_ID}/${productId}`,
          "GET"
        );
        response = result.data;
        dispatch(GetWishlist(userId));
      }

      const productResponse = response.response;
      // console.log("selected product in action==>", {
      //   ...productResponse,
      //   currentVariantDetail: {
      //     productVariantId: 5386816,
      //     varientName: "S / GREEN / DARK",
      //   },
      // });

      // console.log("productVarientId))))))))))))))))))",productVarientId)
      // console.log("inComingProductVarientId---->",inComingProductVarientId)
      const currentVariantDetail = {};
      if (productResponse.productVarient.length > 0) {
        for (
          let index = 0;
          index < productResponse.productVarient.length;
          index++
        ) {
          if (
            productResponse.productVarient[index].productvarientid ==
            parseFloat(inComingProductVarientId)
          ) {
            currentVariantDetail.productVariantId = parseFloat(
              inComingProductVarientId
            );
            currentVariantDetail.varientName =
              productResponse.productVarient[index].varient;
          }
        }
      }

      dispatch({
        type: ActionTypes.SELECTED_PRODUCT,
        payload: {
          ...productResponse,
          // currentVariantDetail: {
          //   productVariantId: 5386816, // added mock data
          //   varientName: "S / GREEN / DARK", // added mock data
          // },

          currentVariantDetail: { ...currentVariantDetail },
        },
      });
    }
  };

// ....................
export const resetSelectedProduct = () => {
  return {
    type: ActionTypes.RESET_SELECTED_PRODUCT,
  };
};

export const removeSelectedProduct = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_PRODUCT,
  };
};

export const setPriceRange = (payload) => {
  return {
    type: ActionTypes.SET_MIN_MAX_PRICE_RANGE,
    payload: payload,
  };
};

export const setInclueOutOfStock = () => (dispatch, getState) => {
  const inclueoutofstock = getState().inclueOutOfStock;
  dispatch({
    type: ActionTypes.SET_INCLUE_OUT_OF_STOCK,
    payload: inclueoutofstock === 0 ? 1 : 0,
  });
};

/**
 * It will add the last selected variation of a product to the cart
 * @param productId - The product id of the product you want to add to the cart.
 * @param cartItems - The array of cart items.
 */
export const addToCartForRepeatPreviousSelectedVariations =
  (productId) => async (dispatch, getState) => {
    const token =
      isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();
    const cartItems = getState().cartItems;
    let newCartArray = [...cartItems];
    let filteredCartArray = newCartArray.filter(
      (x) => x.productid === productId
    );
    if (filteredCartArray.length > 0) {
      let lastSelectedCartItem =
        filteredCartArray[filteredCartArray.length - 1];
      if (lastSelectedCartItem) {
        const result = await axiosFunction(
          token,
          `${ReactProjectBaseUrl}${API_ENDPOINTS.AVAILABLE_QUANTITY}/${lastSelectedCartItem.productVarientId}`,
          "GET"
        );
        if (
          !!result?.data?.status &&
          result?.data?.response > lastSelectedCartItem.quantity
        ) {
          for (const cartItem of newCartArray) {
            if (
              lastSelectedCartItem.productVarientId ===
              cartItem.productVarientId
            ) {
              if (
                cartItem.quantity >= ADD_TO_CART_LIMIT ||
                cartItem.quantity == lastSelectedCartItem.availableQuantity
              ) {
                if (cartItem.quantity >= ADD_TO_CART_LIMIT) {
                  Swal.fire({
                    text: "Maximum limit reached!",
                    iconHtml:
                      '<i class="fas fs-5 fa-check-circle text-success "></i>',
                    target: "#custom-target",
                    showConfirmButton: false,
                    showClass: {
                      popup: "animate__animated animate__slideInUp",
                    },
                    timer: 2000,
                    timerProgressBar: true,
                    customClass: {
                      container: "position-absolute",
                    },
                    toast: true,
                    position: "bottom-right",
                  });
                } else {
                  Swal.fire({
                    text: "Sorry, we don’t have any more units for this item!",
                    iconHtml:
                      '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>',
                    target: "#custom-target",
                    showConfirmButton: false,
                    showClass: {
                      popup: "animate__animated animate__slideInUp",
                    },
                    timer: 2000,
                    timerProgressBar: true,
                    customClass: {
                      container: "position-absolute",
                    },
                    toast: true,
                    position: "bottom-right",
                  });
                }
              } else {
                cartItem.quantity++;
                cartItem.availableQuantity =
                  lastSelectedCartItem.availableQuantity;
                Swal.fire({
                  text: `${TOASTER_MESSAGES.ADD_TO_CART_MESSAGE}`,
                  iconHtml:
                    '<i class="fas fs-5 fa-check-circle text-success "></i>',
                  target: "#custom-target",
                  showConfirmButton: false,
                  showClass: {
                    popup: "animate__animated animate__slideInUp",
                  },
                  timer: 2000,
                  timerProgressBar: true,
                  customClass: {
                    container: "position-absolute",
                  },
                  toast: true,
                  position: "bottom-right",
                });
              }
              break;
            }
          }
        } else {
          globalSwalFunction(
            "No more quantity available for added variant. Please select a different variant.!",
            '<i class="far fa-times-circle text-danger "></i>'
          );
        }
      }
    }

    dispatch({
      type: ActionTypes.ADD_TO_CART,
      payload: newCartArray,
    });
  };
