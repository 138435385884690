import React from "react";
import LocationMenu from "./UserDropDownChildren/LocationMenu";
import UserMenu from "./UserDropDownChildren/UserMenu";

const UserDropDown = ({
  setShowGeoLoacationModalComponent,
  setShowLoginOtpModalComponent,
}) => {
  // Memoize Components
  const MemoizedGeoLocationMenu = React.memo(LocationMenu);
  const MemoizedUserMenu = React.memo(UserMenu);
  return (
    <div className="accountSignInUp item text-end col-sm-4 w-auto">
      <MemoizedGeoLocationMenu
        setShowGeoLoacationModalComponent={setShowGeoLoacationModalComponent}
      />

      {/* <MemoizedUserMenu
        setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
      /> */}
    </div>
  );
};

export default UserDropDown;
