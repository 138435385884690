import { ActionTypes } from "../constants/ActionTypes";
const intialState = [];

export const addToWishlistReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.FETCH_WISHLIST:
      return payload;
      // case ActionTypes.CLEAR_WISHLIST:
      //   return payload;
    default:
      return state;
  }
};
