import { combineReducers } from "redux";
import {
  productsReducer,
  selectedProductsReducer,
  bestSellingProductsReducer,
  totalProductReducer,
  priceRangeReducer,
  inclueOutOfStockReducer,
  trendingProductsReducer,
} from "../reducers/productReducers";
import {
  addToCartReducer,
  addToCartForBuyNowReducer,
} from "../reducers/addToCartReducers";
import {
  loginReducer,
  logoReducer,
  algoliaIndexReducer,
  orderConfirmationSwitchReducer,
  decimalPointsReducer,
  enableWishlistReducer,
  fetchProfileDetailsReducer,
} from "./LoginReducers";
import { bannerReducers } from "../reducers/BannerReducers";
import { topCategoryReducers } from "../reducers/topCategoriesReducer";
import {
  setGeoLocationFlagReducers,
  setCategoriesPageCountReducers,
  setBrandPageCountReducers,
} from "../reducers/DefaultUtilsReducer";
import {
  categoryReducers,
  categoryTotalRecordsCountReducers,
} from "../reducers/CategoryReducer";
import { brandReducers, brandTotalRecordsCountReducers } from "./BrandReducer";
import { AddressReducer } from "./AddressReducer";
import {
  productbrandfilter,
  productcategoryfilter,
} from "./ProductFilterReducer";
import {
  orderDetailsReducer,
  orderListReducer,
  orderCancelReducer,
  orderTrackingTrailReducer,
} from "./OrderReducer";
import { relatedProductsReducer } from "./RelatedProductsReducer";
import { addToWishlistReducer } from "../reducers/WishlistToCartReducer.js";
import { fetchProfileDetails } from "../actions/LoginActions";
import { advertisementReducer } from "./AdvertisementReducer";
import { loadingReducer } from "./isLoadingReducer";
import { faviconLoaderReducer } from "./FaviconLoaderReducer";
const reducers = combineReducers({
  bestSellingProducts: bestSellingProductsReducer,
  trendingProducts: trendingProductsReducer,
  selectedProduct: selectedProductsReducer,
  cartItems: addToCartReducer,
  cartItemWishlist: addToWishlistReducer,
  cartItemsForBuyNow: addToCartForBuyNowReducer,
  companyDetails: loginReducer,
  banners: bannerReducers,
  topCategories: topCategoryReducers,
  geoLocationFlag: setGeoLocationFlagReducers,
  categoriesPageCount: setCategoriesPageCountReducers,
  categories: categoryReducers,
  categoriesTotalCount: categoryTotalRecordsCountReducers,
  brandPageCount: setBrandPageCountReducers,
  brandTotalCount: brandTotalRecordsCountReducers,
  brands: brandReducers,
  products: productsReducer,
  totalProduct: totalProductReducer,
  getaddress: AddressReducer,
  brandFilter: productbrandfilter,
  categoryFilter: productcategoryfilter,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  relatedProducts: relatedProductsReducer,
  orderCancel: orderCancelReducer,
  pricerange: priceRangeReducer,
  inclueOutOfStock: inclueOutOfStockReducer,
  logo: logoReducer,
  algoliaIndex: algoliaIndexReducer,
  orderTrackingTrail: orderTrackingTrailReducer,
  isOrderConfirmation: orderConfirmationSwitchReducer,
  decimalPoints: decimalPointsReducer,
  enableWishlist: enableWishlistReducer,
  profileDetails: fetchProfileDetailsReducer,
  ads: advertisementReducer,
  faviconLoader: faviconLoaderReducer,
  loading: loadingReducer,
});
export default reducers;
