import { useSelector, useDispatch } from "react-redux";
import { axiosFunction } from "../utils/globalFunctions/axiosFunction";
import { globalSwalFunction } from "../utils/globalFunctions/globalSwal";
import { ReactProjectBaseUrl } from "../utils/Constants";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getAddress } from "../State/actions/AddressActions";
import { ActionTypes } from "../State/constants/ActionTypes";
import { defaultImage } from "../utils/productUtils/ProductUtils";
import useCustomerActiveOrNot from "./useCustomerActiveOrNot";
import { ClearCart } from "../State/actions/addToCartActions";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../utils/get-token";

const useAuth = (type) => {
  const { cartItems, decimalPoints } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerActiveOrNotFunc } = useCustomerActiveOrNot();

  // User auth or not checking...
  const checkUserAuthenticateOrNot = async (
    ShowHideSideBarCartComponentEventHandler,
    setShowLoginOtpModalComponent,
    setshowHideLoginForm
  ) => {
    const userID = Cookies.get("ID");
    const token =
      isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();
    if (!userID) {
      if (type === "sidebarCart") {
        ShowHideSideBarCartComponentEventHandler(false);
        setShowLoginOtpModalComponent(true);
        return;
      } else {
        setshowHideLoginForm(true);
        return;
      }
    }

    const isActive = await customerActiveOrNotFunc(userID);

    if (isActive === 1) {
      globalSwalFunction(
        "Customer is Deactivated",
        '<i class="fas fa-times-circle text-danger fs-5"></i>',
        { position: "top-right" }
      );
      navigate("/access-denied");
      Cookies.remove("ID");
      Cookies.remove("CONTACT_NUMBER");
      Cookies.remove("CUSTOMER_NAME");
      Cookies.remove("CONTACT_EMAIL");
      Cookies.remove("CUSTOMER_FIRST_NAME");
      Cookies.remove("CUSTOMER_LAST_NAME");
      dispatch(ClearCart());
      return;
    }

    const res = await axiosFunction(
      token,
      `${ReactProjectBaseUrl}${API_ENDPOINTS.CART_LIST}`,
      "POST",
      {
        cartDTOs: cartItems,
      }
    );

    if (!res.status) {
      return;
    }

    const { response } = res.data;

    if (!response?.cartDTOs || response.cartDTOs.length === 0) {
      return;
    }

    const updatedCartItems = response.cartDTOs.reduce((acc, item) => {
      const cartItem = cartItems.find(
        (productItem) => productItem.productVarientId === item.productVarientId
      );

      if (!cartItem) {
        return acc;
      }

      const latestQuantity = Math.min(
        item.availableQuantity < 0 ? 0 : item.availableQuantity,
        cartItem.quantity
      );

      acc.push({
        productVarientId: item.productVarientId,
        price: item.sellingprice,
        quantity: Math.floor(latestQuantity),
        imgSrc: item.imgSrc || defaultImage,
        productName: item.name,
        productid: item.productId,
        isFromBuyNow: 0,
        availableQuantity: Math.floor(item.availableQuantity),
        variantName: item.variantName,
        activeOnline: item.activeOnline,
      });

      return acc;
    }, []);

    // const hasChanges = cartItems.some((cartItem) => {
    //   const updatedCartItem = updatedCartItems.find(
    //     (item) => item.productVarientId === cartItem.productVarientId
    //   );

    //   if (!updatedCartItem) {
    //     return true;
    //   }
    //   console.log(
    //     !updatedCartItem.availableQuantity >= cartItem.quantity &&
    //     updatedCartItem.price !== cartItem.price,
    //     "ddfjfj"
    //   );
    //   debugger;
    //   return (
    //     !updatedCartItem.availableQuantity >= cartItem.quantity &&
    //     updatedCartItem.price !== cartItem.price
    //   );
    // });
    const compareBuyNowProduct = cartItems.every((product) => {
      return updatedCartItems.some((revampProduct) => {
        return (
          // updatedCartItem.price !== cartItem.price ||
          // updatedCartItem.quantity !== cartItem.quantity ||
          // updatedCartItem.availableQuantity !== cartItem.availableQuantity ||
          revampProduct.activeOnline === product.activeOnline &&
          revampProduct.availableQuantity >= product.quantity &&
          revampProduct.price === product.price
        );
      });
    });

    if (compareBuyNowProduct) {
      dispatch(getAddress(userID));
      navigate("/checkout", { state: "normalcheckout" });
      return;
    } else if (!compareBuyNowProduct) {
      globalSwalFunction(
        "There are some changes in your cart",
        '<i class="fas fa-times-circle text-danger fs-5"></i>'
      ).then(() => navigate("/checkout", { state: "normalcheckout" }));
    }

    dispatch({
      type: ActionTypes.ADD_TO_CART,
      payload: updatedCartItems,
    });

    dispatch(getAddress(userID));
    // navigate("/checkout", { state: "normalcheckout" });
  };

  return { checkUserAuthenticateOrNot };
};

export default useAuth;
