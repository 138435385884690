import React, { useState, useEffect, useRef } from "react";
import { defaultImage } from "../utils/productUtils/ProductUtils";

export function ProductItem({
  hit,
  components,
  setSelectedProductEventHandler,
}) {
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowProfileDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // const companyDetails = useSelector((state) => state.companyDetails);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <div
        className="aa-ItemLink productCard"
        id={hit.product_id}
        // onContextMenu={() => setSelectedProductEventHandler(hit.productId)}
        onKeyPress={(e) => e === "Enter"}
        onClick={(e) =>
          setSelectedProductEventHandler(hit.product_id, Number(hit.objectID))
        }
      >
        <div className="aa-ItemContent">
          <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultImage;
              }}
              src={hit.image_path ? hit.image_path : defaultImage}
              alt={hit.product_variant_name}
              width="40"
              height="40"
            />
          </div>
          <div className="aa-ItemContentBody">
            <div className="aa-ItemContentTitle">
              {/* <components.Highlight hit={hit} attribute="product_variant_name" /> */}
              {hit.product_variant_name}
            </div>
            <div className="aa-ItemContentDescription">
              {/* From <strong>{hit.brand}</strong> in{' '} */}
              From in{" "}
              <strong
                dangerouslySetInnerHTML={{ __html: hit.category_name }}
              ></strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
