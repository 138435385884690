import Cookies from "js-cookie";
import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../utils/logout";

const UserMenu = ({ setShowLoginOtpModalComponent }) => {
  const navigate = useNavigate();
  const enableWishlist = useSelector((state) => state.enableWishlist);
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const wrapperRef = useRef(null);

  const { profileDetails } = useSelector((state) => state);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowProfileDropDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const profileDropDownBox = (path) => {
    if (path !== false) {
      navigate(path);
      setShowProfileDropDown(false);
    } else {
      logoutEventHandler();
    }
  };

  const showLoginOtpModalEventHandler = (type) => {
    if (type === "open") {
      setShowLoginOtpModalComponent(true);
      document.getElementById("mobileNoInput").focus();
    } else {
      setShowLoginOtpModalComponent(false);
    }
  };

  const logoutEventHandler = () => {
    const result = logout();
    if (result) {
      navigate("../", { replace: true });
      setShowProfileDropDown(false);
      window.location.reload(true);
    }
  };
  const showProfileDropDownOnClick = () => {
    setShowProfileDropDown((prevState) => !prevState);
  };

  const profileModalArray = [
    {
      id: 1,
      name: "My Profile",
      type: "profile",
      enableWishlist: 1,
      path: "/myprofile",
      icon: <i className="fas fa-user-circle pe-2" aria-hidden="true"></i>,
    },
    {
      id: 2,
      name: "Wishlist",
      type: "wishlist",
      enableWishlist: enableWishlist,
      path: "/mywishlist",
      icon: <i className="fas fa-heart buttons pe-2" aria-hidden="true"></i>,
    },
    {
      id: 3,
      name: "Orders",
      path: "/orders",
      enableWishlist: 1,
      type: "orders",
      icon: <i className="fas fa-shopping-bag pe-2" aria-hidden="true"></i>,
    },
    {
      id: 4,
      name: "Logout",
      type: "logout",
      enableWishlist: 1,
      path: false,
      icon: <i className="fas fa-sign-out-alt pe-2" aria-hidden="true"></i>,
    },
  ];

  return (
    <div className="d-none d-sm-block flex-row position-relative">
      <div
        className="group col align-items-center account active d-flex"
        hidden={Cookies.get("ID") ? false : true}
        style={{cursor:"pointer"}}
        onClick={() => showProfileDropDownOnClick()}
      >
        <i className="fas fa-user-circle" aria-hidden="true"></i>
        <div className="detail">
          <h6 className="m-0 pe-sm-2 fw-bold">
            {Cookies.get("CUSTOMER_NAME") ||
              `${profileDetails.firstName} ${profileDetails.lastName}`}
          </h6>
        </div>
      </div>
      <div
        className={`triangle-with-shadow ${showProfileDropDown && "show"}`}
      ></div>
      {Cookies.get("ID") && (
        <ul
          ref={wrapperRef}
          className={`dropdown-menu ${showProfileDropDown && "show"}`}
          aria-labelledby="navbarDropdown"
        >
          {profileModalArray.map((userMenuObj) => {
            return (
              userMenuObj.enableWishlist == 1 && (
                <li key={userMenuObj.id}>
                  <a
                    className="dropdown-item"
                    onClick={() => profileDropDownBox(userMenuObj.path)}
                  >
                    {userMenuObj.icon}
                    {userMenuObj.name}
                  </a>
                </li>
              )
            );
          })}
        </ul>
      )}
      <div
        className="group accountSignInUp col align-items-center loginIn active d-flex"
        onClick={() => {
          showLoginOtpModalEventHandler("open");
          setTimeout(() => {
            document.getElementById("mobileNoInput").focus();
          }, 500);
        }}
        hidden={Cookies.get("ID") ? true : false}
      >
        <i className="material-icons login_icon">login</i>
        <div className="detail">
          <h6 className="m-0 pe-sm-2">Sign In</h6>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
