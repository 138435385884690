import React from "react";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import { Autocomplete } from "../AutoComplete";
import { ProductItem } from "../ProductItem";

import { autocomplete } from "@algolia/autocomplete-js";
import Typesense from "typesense";
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter";

import { useDispatch, useSelector } from "react-redux";
import { selectedProduct } from "../../State/actions/productActions";
import { useNavigate } from "react-router-dom";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_APP_KEY,
  TYPESENSE_CREDENTIALS,
} from "../../utils/Constants";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_APP_KEY);

const NavbarSearchSection = () => {
  const algoliaIndex = useSelector((state) => state.algoliaIndex);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setSelectedProductEventHandler = (productid, id) => {
    dispatch(selectedProduct(productid, id));
    navigate("/productView/" + productid);
  };

  const search_response_adapter = (result) =>
    new SearchResponseAdapter(
      result,
      { params: {} },
      { geoLocationField: "_geoloc" }
    );

  const typesense_client = () =>
    new Typesense.Client({
      apiKey: TYPESENSE_CREDENTIALS.apiKey,
      nodes: [
        {
          host: TYPESENSE_CREDENTIALS.host,
          port: TYPESENSE_CREDENTIALS.port,
          protocol: TYPESENSE_CREDENTIALS.protocol,
        },
      ],
      connectionTimeoutSeconds: 2,
    });

  const client = typesense_client();

  return (
    <>
      <div className="item search right col-sm-5 navSearchbar" tabIndex="0">
        <Autocomplete
          openOnFocus={false}
          placeholder="What are you looking for..."
          navigator
          getSources={({ query }) => [
            {
              sourceId: "products",
              templates: {
                item({ item, components }) {
                  {
                    (() => {
                      return null;
                    })();
                  }
                  return (
                    <ProductItem
                      hit={item}
                      components={components}
                      setSelectedProductEventHandler={
                        setSelectedProductEventHandler
                      }
                    />
                  );
                },
                noResults() {
                  return "No results.";
                },
              },

              getItems: () =>
                client
                  .collections(algoliaIndex)
                  .documents()
                  .search({
                    q: query,
                    query_by: "product_variant_name,item_code",
                    filter_by: "show_online:1",
                  })
                  .then((result) => {
                    return search_response_adapter(result).adapt().hits;
                  }),

              getItemInputValue({ item }) {
                return item.product_variant_name;
              },
              onSelect({ item }) {
                // debugger;
                setSelectedProductEventHandler(
                  item.product_id,
                  Number(item.objectID)
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export default NavbarSearchSection;
