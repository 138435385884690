import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesFilterAction } from "../../State/actions/CategoriesFilterAction";
import { useNavigate } from "react-router-dom";
import { clearBrandAction } from '../../State//actions/BrandFilterActions'

const MegaMenuItems = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addCategoriesFilter = async (id, categoryName) => {
    window.scrollTo(0, 0);
    dispatch(clearBrandAction());
    await dispatch(  getCategoriesFilterAction(id, categoryName));
    navigate(`/shop/${id}`, { replace: true });
    props.setMegamenuItem(false);
        
  };
  return props.categoryItems.map((cartItemVo, index) => (
    <li   key={index}>
      <div
        // to="../shop"
        onContextMenu = {()=> addCategoriesFilter(cartItemVo.categoryId, cartItemVo.categoryName)}
        onClick={() => {
          addCategoriesFilter(cartItemVo.categoryId, cartItemVo.categoryName);
        }}
      
        dangerouslySetInnerHTML={{__html: cartItemVo.categoryName }}
      >
        
      </div>
    </li>
  ));
};

export default MegaMenuItems;
