import React, { useEffect, useState } from "react";
import MegaMenuItems from "./MegaMenuItems";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesFilterAction } from "../../State/actions/CategoriesFilterAction";
import { Link, useNavigate } from "react-router-dom";

const NavBarMegaMenu = () => {
  const categories = useSelector((state) => state.categories);
  const [
    alphabeticallyFilteredAndBifurcatedArray,
    setAlphabeticallyFilteredAndBifurcatedArray,
  ] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addCategoriesFilter = (id, categoryName) => {
    dispatch(getCategoriesFilterAction(id, categoryName));
    navigate(`/shop/${id}`, { replace: true });
  };

  /**
   * Sort the array of objects by the categoryName property in ascending order.
   * @param x - The first item to be compared.
   * @param y - The second item to compare.
   * @returns a sorted array of objects.
   */
  const SortArrayAtoZ = (x, y) => {
    if (x.categoryName < y.categoryName) {
      return -1;
    }
    if (x.categoryName > y.categoryName) {
      return 1;
    }
    return 0;
  };

  /**
   * If the categoryId of the first object is less than the categoryId of the second object, return
   * -1. If the categoryId of the first object is greater than the categoryId of the second object,
   * return 1. Otherwise, return 0
   * @param x - The first item to compare.
   * @param y - The second item to compare.
   * @returns a function that takes two arguments.
   */
  const SortArrayDefault = (x, y) => {
    if (x.categoryId < y.categoryId) {
      return -1;
    }
    if (x.categoryId > y.categoryId) {
      return 1;
    }
    return 0;
  };

  /**
   * It takes an array of objects, sorts it alphabetically, then creates a new array of objects with
   * the first letter of each object's name as the key, and the array of objects that start with that
   * letter as the value
   * @param categoriesArray - This is the array of objects that you want to sort.
   * @returns An array of objects with the alphabet as the key and the filtered array as the value.
   */
  const getAlphabeticBifurcatedArray = (categoriesArray) => {
    let AlphabeticArray = [];
    let AlphabeticArrayFilteredObjects = [];
    const sortedArray = categoriesArray.sort(SortArrayAtoZ);

    sortedArray.forEach((categoriesVo) => {
      if (
        !AlphabeticArray.includes(
          categoriesVo.categoryName.charAt(0).toUpperCase()
        )
      ) {
        AlphabeticArray.push(categoriesVo.categoryName.charAt(0).toUpperCase());
      }
    });

    let counter = 0;
    AlphabeticArray.forEach((alphabet) => {
      let filteredArray = sortedArray.filter(
        (x) => x.categoryName.charAt(0).toUpperCase() === alphabet
      );
      AlphabeticArrayFilteredObjects.push({ alphabet, filteredArray });
      counter += filteredArray.length;
    });
    return AlphabeticArrayFilteredObjects;
  };

  useEffect(() => {
    let newArray = getAlphabeticBifurcatedArray(categories);
    setAlphabeticallyFilteredAndBifurcatedArray(newArray);
  }, [categories]);

  const [megamenuItem, setMegamenuItem] = useState(false);

  return (
    <nav className="d-none">
      <div className="wrapper">
        <div id="meGaoverlay"></div>
        <div className="megamenu_container megamenu_dark_bar megamenu_light">
          {/* <!-- Begin Menu Container --> */}
          <ul className="megamenu">
            {/* <!-- Begin Mega Menu --> */}
            <li
              onMouseEnter={() => setMegamenuItem(true)}
              onMouseLeave={() => setMegamenuItem(false)}
            >
              <a className="megamenu_drop categoriesMegaAll">
                All Categories
                <i className="fas fa-angle-down mt-2 ps-1"></i>
              </a>

              <div
                className="dropdown_8columns dropdown_container row"
                style={megamenuItem ? { left: "15px" } : { left: "-999999em" }}
              >
                {/* <!-- Begin Item Container --> */}

                <div className="col-12  responsive_halfs">
                  <ul className="list">
                    {getAlphabeticBifurcatedArray(categories).map(
                      (categoryItems, index) => (
                        <div key={index}>
                          <li className="megaMenuNavLinkHeading">
                            <h2>--{categoryItems.alphabet}--</h2>
                          </li>
                          <MegaMenuItems
                            setMegamenuItem={setMegamenuItem}
                            categoryItems={categoryItems.filteredArray}
                          />
                        </div>
                      )
                    )}
                  </ul>
                </div>
              </div>
              {/* <!-- End Item container --> */}
            </li>

            {categories.sort(SortArrayDefault).map((categoryItems, index) => {
              if (index >= 20) {
                return false;
              } else {
                return (
                  <li key={index} style={{ margin: "0px 7px" }}
                  to="../shop"
                  onContextMenu = {()=> addCategoriesFilter(
                    categoryItems.categoryId,
                    categoryItems.categoryName
                  )}
                  onClick={() =>
                    addCategoriesFilter(
                      categoryItems.categoryId,
                      categoryItems.categoryName
                    )
                  }
                  >
                    <Link
                      to={`/shop/${categoryItems.categoryId}`}
                      className="megamenu_drop text-center"
                      
                      dangerouslySetInnerHTML={{__html: categoryItems.categoryName }}
                    >
                      
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
          {/* <!-- End Mega Menu --> */}
        </div>
        {/* <!-- End Menu Container --> */}
      </div>
    </nav>
  );
};

export default NavBarMegaMenu;
