import { ActionTypes } from "../constants/ActionTypes";
import { getBrandsByCategoryAction } from "./BrandActions";

export const getCategoriesFilterAction =
  (id, categotryName) => async (dispatch, getState) => {
    await dispatch({ type: ActionTypes.SET_BRANDS_FILTER, payload: [] });
    const categoryFilter = getState().categoryFilter;
    // let newArray = [...categoryFilter];
    let newArray = [];
    if (id === 0) {
    } else {
      let categoryObj = {
        id: id,
        categoryName: categotryName,
      };
      if (newArray.includes(id)) {
        newArray = newArray.filter((x) => x.id !== id);
      } else {
        newArray.push(categoryObj);
      }
    }

    dispatch({ type: ActionTypes.SET_CATEGORY_FILTER, payload: newArray });

    dispatch(await getBrandsByCategoryAction());
  };
