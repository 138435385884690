import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { axiosFunction } from "../../../utils/globalFunctions/axiosFunction";
import { ReactProjectBaseUrl } from "../../../utils/Constants";
import { API_ENDPOINTS } from "../../../utils/api-endpoints";
import http from "../../../utils/http";
import { globalSwalFunction } from "../../../utils/globalFunctions/globalSwal";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { getAddress } from "../../../State/actions/AddressActions";
import { GetWishlist } from "../../../State/actions/WishlistActions";
import PrimaryButton from "../../PrimaryButton";
import { useNavigate } from "react-router-dom";
import { ClearCart } from "../../../State/actions/addToCartActions";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../../../utils/get-token";

const OtpDialogueModal = ({
  counter,
  isLoading,
  setIsLoading,
  OTP,
  setOTP,
  requestData,
  MobileNo,
  setMobileNo,
  ShowResendBtn,
  setShowOtpVarificationComponent,
  setShowMobileNoComponent,
  setShowSignUpComponent,
  sendOtp,
  showLoginOtpModalEventHandler,
  isLoadingResendOtp,
  verifyOtpMessage,
  setVerifyOtpMessage,
  setSendOtpMessage,
  sendOtpMessage,
}) => {
  const dispatch = useDispatch();
  const [OTPAuto, setOTPAuto] = useState(0);

  const navigate = useNavigate();

  // console.log("sendOtpMessage", sendOtpMessage);
  // console.log("verifyOtpMessage", verifyOtpMessage);

  const verifyOtp = async () => {
    const token = getApiToken();
    setIsLoading(true);
    if (OTP) {
      try {
        const res = await axiosFunction(
          token,
          `${ReactProjectBaseUrl}${API_ENDPOINTS.VERIFY_OTP}/?mobileNo=${MobileNo}&otp=${OTP}`,
          "POST",
          requestData
        );

        const verifyOtpResponse = res.data;

        // console.log("verifyOtpResponse", verifyOtpResponse);

        if (verifyOtpResponse?.status) {
          const res = await axiosFunction(
            token,
            `${ReactProjectBaseUrl}${API_ENDPOINTS.IS_EXISTING_USER}?mobileNo=${MobileNo}`,
            "POST"
          );

          globalSwalFunction(
            "OTP verified Successfully!",
            '<i class="fas fs-5 fa-check-circle text-success "></i>'
          );

          setTimeout(() => {
            if (res.data.response.isExistingCustomer === "0") {
              setIsLoading(false);
              setShowMobileNoComponent(false);
              setShowOtpVarificationComponent(false);
              setShowSignUpComponent(true);
            } else if (res.data.response.isactive === "1") {
              globalSwalFunction(
                "Customer is Deactivated",
                '<i class="fas fa-times-circle text-danger fs-5"></i>',
                { position: "top-right" }
              );
              navigate("/access-denied");
              dispatch(ClearCart());
            } else {
              setIsLoading(false);
              globalSwalFunction(
                `Welcome back "${res.data.response.contactName}"`,
                '<i class="fas fs-5 fa-check-circle text-success "></i>'
              );
              const splitFUllName = res.data.response.firstName;
              Cookies.set("CUSTOMER_NAME", res.data.response.contactName);
              Cookies.set("ID", res.data.response.isExistingCustomer);
              Cookies.set("CONTACT_NUMBER", res.data.response.contactMobileNo);
              Cookies.set("CONTACT_EMAIL", res.data.response.email);
              Cookies.set("CUSTOMER_FIRST_NAME", splitFUllName.split(" ")[0]);
              Cookies.set(
                "CUSTOMER_LAST_NAME",
                res.data.response.lastName.length > 0
                  ? res.data.response.lastName
                  : splitFUllName.split(" ")[1]
              );
              showLoginOtpModalEventHandler("close");
              setShowMobileNoComponent(true);
              setShowOtpVarificationComponent(false);
              setShowSignUpComponent(false);
              setMobileNo("");
              try {
                dispatch(getAddress(Cookies.get("ID")));
                dispatch(GetWishlist(Cookies.get("ID")));
              } catch (error) {
                throw new Error(error);
              }
            }
          }, 1000);
        } else {
          setIsLoading(false);
          if (verifyOtpResponse?.response?.count === "0") {
            setVerifyOtpMessage(verifyOtpResponse?.response?.message);
            showLoginOtpModalEventHandler("close");
            globalSwalFunction(
              `Invalid OTP! ${" " + verifyOtpResponse?.response?.message}`,
              '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
            );
          } else {
            setVerifyOtpMessage("Invalid OTP! " + verifyOtpResponse?.response);
            setOTP(0);
            document.querySelector(".otpInputItem:first-child input").focus();
            setIsLoading(false);
            globalSwalFunction(
              "Invalid OTP!",
              '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
            );
          }
        }
      } catch (error) {
        setIsLoading(false);
        globalSwalFunction(
          "Something Went Wrong...!!!",
          '<i class="fas fa-times-circle text-danger fs-5"></i>'
        );
      }
    } else {
      setIsLoading(false);
      globalSwalFunction(
        "Please enter OTP!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
    }
  };
  // useEffect(() => {
  //   var otpArrayIndex = document.getElementsByClassName("otpInputItem");
  //   var otpArrayLastIndex = otpArrayIndex.length - 1;

  //   var otpLastInput = otpArrayIndex[otpArrayLastIndex].querySelector("input");
  //   otpLastInput.addEventListener("keypress", function (e) {
  //     if (e.charCode === 13) {
  //       document.getElementById("otp-submit-button").click();
  //     }
  //   });

  //   return () => {};
  // }, []);

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      verifyOtp();
    }
  };

  useEffect(() => {
    const onEnterTheLastOtpInput = () => {
      const otpArrayIndex = document.getElementsByClassName("otpInputItem");
      const otpArrayLastIndex = otpArrayIndex.length - 1;
      const otpLastInput =
        otpArrayIndex[otpArrayLastIndex].querySelector("input");

      const handleKeyPress = (e) => {
        if (e.charCode === 13) {
          document.getElementById("otp-submit-button").click();
        }
      };

      otpLastInput.addEventListener("keypress", handleKeyPress);

      return () => {
        otpLastInput.removeEventListener("keypress", handleKeyPress);
      };
    };
    onEnterTheLastOtpInput();
  }, []);
  const goBackOnMobileNoComponent = () => {
    setIsLoading(false);
    setShowMobileNoComponent(true);
    setShowOtpVarificationComponent(false);
    setShowSignUpComponent(false);
    setMobileNo("");
    setOTP(0);
    setSendOtpMessage("");
    const mobileNoInput = document.getElementById("mobileNoInput");
    if (mobileNoInput) {
      mobileNoInput.focus();
    }
  };
  const otpOnChangeEventhandler = (otpValue) => {
    setOTP(otpValue);
  };
  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          setOTPAuto(otp.code);
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
        });
    }
  }, []);

  // console.log("sendOtpMessage", sendOtpMessage);

  return (
    <div
      className="form-container sign-in-container validateMobileRequest"
      style={{
        display: "block",
      }}
    >
      <div
        // className="accountCredFormClose"
        onClick={() => goBackOnMobileNoComponent()}
        style={{
          left: "1%",
          top: "2%",
          position: "absolute",
          top: "3%",
          cursor: "pointer",
          zIndex: 111111,
        }}
      >
        <i className="material-icons search-icon">arrow_back</i>
      </div>
      <div className="form" id="otp-form">
        <div className="Otp_title_container w-100 mt-3  ">
          <h1 className="fs-5 fw-bolder lh-base">OTP verification</h1>
        </div>
        <div className="checkotp_title_container">
          <span>
            Please check the OTP sent to your mobile number {MobileNo}
          </span>
        </div>
        {/* <div className="checkotp_title_container">
          <span className="pt-3"></span>
        </div> */}
        {/* <div className="changenum_title_container">
          <a className="m-0" onClick={() => goBackOnMobileNoComponent()}>
            <span style={{ color: "var(--primary-color)", cursor: "pointer" }}>
              Click here to change the number
            </span>
          </a>
        </div> */}

        <div className="otpContainer">
          <OtpInput
            inputStyle={{
              width: "2rem",
              height: "2rem",
              margin: "0 0 0 10px",
              padding: "0px",
              fontSize: "12px",
              borderRadius: 8,
              border: "1px solid var(--primary-color)!important",
            }}
            isInputSecure={false}
            onChange={otpOnChangeEventhandler}
            numInputs={6}
            className="otpInputItem"
            separator={<span></span>}
            value={OTP}
            isInputNum={true}
            autoFocus
            isDisabled={sendOtpMessage}
          />
        </div>
        {/* <span className="CountDown text-danger">Countdown: 4.59 Min</span> */}
        {/* {verifyOtpMessage !== "Resend OTP limit exhausted" && ( */}
        <span
          hidden={ShowResendBtn}
          style={{ color: "black", fontWeight: "bold", alignSelf: "start" }}
        >
          Resend OTP in 00:{counter}
        </span>
        {/* )} */}
        <span
          className="text-danger"
          style={{ fontWeight: "bold", alignSelf: "start" }}
        >
          {verifyOtpMessage ? verifyOtpMessage : sendOtpMessage}
        </span>
        {/* {verifyOtpMessage !== "Resend OTP limit exhausted" && ( */}
        <>
          <span className="mr-3" hidden={!ShowResendBtn}>
            <span style={{ marginRight: "10px" }}>Didn't received OTP?</span>{" "}
            <PrimaryButton
              id={"resendButton"}
              type="button"
              className="resendButton"
              onClick={() => sendOtp("resend")}
              icon={
                <i
                  className={isLoadingResendOtp ? "fa fa-spinner fa-spin" : ""}
                  style={{ marginRight: "5px" }}
                ></i>
              }
              title="Resend OTP"
              name="Resend OTP"
            />
          </span>
        </>
        {/* )} */}
        <PrimaryButton
          id={"otp-submit-button"}
          type="button"
          className="hero accountCredPopupBtn"
          onClick={() => verifyOtp()}
          icon={
            <i
              className={isLoading ? "fa fa-spinner fa-spin" : ""}
              style={{ marginRight: "5px" }}
            ></i>
          }
          title="Let's go!"
          name="Let's go!"
          handleKeyPress={(e) => handleKeyPress(e)}
        />
      </div>
    </div>
  );
};

export default OtpDialogueModal;
