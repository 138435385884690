import { ActionTypes } from "../constants/ActionTypes";

const intialState = [];

export const addToCartReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART:
      return payload;
    case ActionTypes.REMOVE_TO_CART:
      return payload;
    case ActionTypes.CLEAR_CART:
      return payload;
    case ActionTypes.POPULATE_CART_ITEM:
      return { ...state, cartItems: payload };
    case ActionTypes.ADD_QTY_INTO_CART:
      return { ...state, cartItems: payload };
    case ActionTypes.REMOVE_QTY_FROM_CART:
      return payload;
    case ActionTypes.SET_CART_ITEMS_AGAIN:
      return { ...state, cartItems: payload };
    default:
      return state;
  }
};

export const addToCartForBuyNowReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART_FOR_BUY_NOW:
      return payload;
    case ActionTypes.CLEAR_CART_FOR_BUY_NOW:
      return payload;
    default:
      return state;
  }
};
