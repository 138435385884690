import React, { memo, useEffect, useMemo } from "react";

const InputComponent = ({
  id,
  mandatoryField,
  option,
  label,
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  placeHolder,
  icon = true,
  ...props
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id} className="form-label popup-form-label">
          {label}
          {mandatoryField && <span className="text-danger">*</span>}
          {option && <small className=" popup-form-label"> (Optional)</small>}
        </label>
      )}
      <input
        inputMode="text"
        type="text"
        className="form-control"
        id={id}
        placeholder={placeHolder ?? label}
        aria-label={label}
        value={values[id]}
        required
        name={id}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      {errors && (
        <small className="text-danger">
          {errors[id] && touched[id] ? errors[id] : null}
        </small>
      )}
      {!icon && <i className="material-icons profileEditIcons m-3">edit_off</i>}
    </>
  );
};

export default InputComponent;
