import { ReactProjectBaseUrl } from "../../utils/Constants";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { getApiToken } from "../../utils/get-token";
import http from "../../utils/http";
import { ActionTypes } from "../constants/ActionTypes";

export const getFaviconGIFLoader = () => async (dispatch) => {
  const token = getApiToken()
    // Update headers with the token
    http.defaults.headers.common['API_TOKEN'] =  token;
  const response = await http.get(
    ReactProjectBaseUrl + API_ENDPOINTS.GET_FAV_LOADER
  );
  const result = response.data;
  let favicon_loader_data = [];

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    link.setAttribute("as", "image");
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  if (result.status) {
    favicon_loader_data = result.response;
    let faviconSrc = favicon_loader_data.filter(
      (data) => data.utilityDriveType === "favicon"
    );
    if (faviconSrc.length > 0) {
      link.href = faviconSrc[0]?.signedSrc;
    } else {
      link.href = "https://www.vasyerp.com/image/favicon.png";
    }
  }else {
    link.href = "https://www.vasyerp.com/image/favicon.png";
  }

  dispatch({
    type: ActionTypes.GET_FAVICON_LOADER,
    payload: favicon_loader_data,
  });
};
