import { useEffect } from "react";
import useCmsPage from "../../../hooks/useCmsPage";
import { removeHtmlTags } from "../../../utils/cmsUtils/cmsUtils";
import "./cmsPage.css";
import ReactLoading from "react-loading";
import { LoaderColor } from "../../../utils/productUtils/ProductUtils";

const CmsPageComponent = ({ type, header }) => {
  const { fetchData, fetchCmsPage } = useCmsPage();

  useEffect(() => {
    fetchCmsPage(type);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container py-sm-3 ">
        <div className="row text-center">
          <div className="col-lg-12 text-start ">
            {fetchData.length <= 0 ? (
              <div className="ImageLoader custom_loader">
                <ReactLoading
                  type="spinningBubbles"
                  color={LoaderColor}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <div dangerouslySetInnerHTML={removeHtmlTags(fetchData)} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CmsPageComponent;
