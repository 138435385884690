import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useSubTotalPrice = () => {
  const { cartItems } = useSelector((state) => state);

  //   SubTotal State.
  const [subTotal, setSubTotal] = useState(
    cartItems.filter(item => item.activeOnline === 1).filter(item => item.availableQuantity >= 1).reduce(
      (acc, cartItem) => acc + cartItem.price * cartItem.quantity,
      0
    )
  );

  // subTotal price -- useEffect.
  useEffect(() => {
    const subTotalPrice = cartItems.filter(item => item.activeOnline === 1).filter(item => item.availableQuantity >= 1).reduce(
      (acc, cartItem) => acc + cartItem.price * cartItem.quantity,
      0
    );
    setSubTotal(subTotalPrice);
  }, [cartItems]);

  return subTotal;
};

export default useSubTotalPrice;
