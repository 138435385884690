import  {ActionTypes}  from "../constants/ActionTypes";
const DefultGeoLocationFlag = false ;
const defaultCategoriesPageCount = 0
const defaultBrandPageCount = 0;

export const setGeoLocationFlagReducers = (state = DefultGeoLocationFlag, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LOCATION_FETCHED:
      return payload;
    default:
      return state;
  }
};

export const setCategoriesPageCountReducers = (state = defaultCategoriesPageCount, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CATEGORY_COUNTER:
      return payload;
    default:
      return state;
  }
};

export const setBrandPageCountReducers = (state = defaultBrandPageCount, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BRAND_COUNTER:
      return payload;
    default:
      return state;
  }
};