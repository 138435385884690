import React from "react";

import GeoLocationModalComponent from "../geoLocationModal/GeoLocationModalComponent";
import LoginOtpModalComponent from "../loginOtpModals/LoginOtpModalComponent";

import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import UserDropDown from "./NavbarRightChildren/UserDropDown";

const NavbarRightSection = ({
  showGeoLoacationModalComponent,
  setShowGeoLoacationModalComponent,
  ShowLoginOtpModalComponent,
  setShowLoginOtpModalComponent,
  showHideLoginForm,
  setshowHideLoginForm,
}) => {
  const fireToast = () => {
    if (!showGeoLoacationModalComponent) {
      globalSwalFunction(
        "Specify location to proceed further!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
    }
  };

  // Memoize Components
  const MemoizedGeoLocationModalComponent = React.memo(
    GeoLocationModalComponent
  );
  const MemoizedUserDropDown = React.memo(UserDropDown);

  return (
    <>
      {!showGeoLoacationModalComponent && (
        <div
          id="cd-shadow-layer"
          className="is-visible"
          style={{ cursor: "default" }}
          // onClick={() => fireToast()}
        ></div>
      )}
      {!showGeoLoacationModalComponent && (
        <MemoizedGeoLocationModalComponent
          flag={showGeoLoacationModalComponent}
          setShowGeoLoacationModalComponent={setShowGeoLoacationModalComponent}
        />
      )}
      <MemoizedUserDropDown
        setShowGeoLoacationModalComponent={setShowGeoLoacationModalComponent}
        setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
      />

      <LoginOtpModalComponent
        setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
        ShowLoginOtpModalComponent={ShowLoginOtpModalComponent}
        setshowHideLoginForm={setshowHideLoginForm}
        showHideLoginForm={showHideLoginForm}
      />
    </>
  );
};

export default NavbarRightSection;
