import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  status: false,
  message: "Default State",
  response: null,
};

export const loginReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    // case ActionTypes.SET_USER_MOBILE_NO:
    //   return { ...state, userMobileNo:payload };
    case ActionTypes.SET_COMPANY_DETAILS_FROM_DOMAIN:
      if (payload.status) {
      }

      return payload;

    case ActionTypes.SEND_OTP:
      if (payload.status) {
      }
    case ActionTypes.SET_LOGO:
      let logo = "images/VasyERP.png";
      if (payload) {
        logo = payload;
      }
      return logo;
    case ActionTypes.SET_ALGOLIA_INDEX_NAME:
      let indexName = "";
      if (payload) {
        indexName = payload;
      }
      return indexName;

    default:
      return state;
  }
};

export const logoReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LOGO:
      let logo = "images/VasyERP.png";
      if (payload) {
        logo = payload;
      }
      return logo;
    default:
      return state;
  }
};

export const algoliaIndexReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ALGOLIA_INDEX_NAME:
      let indexName = "";
      if (payload) {
        indexName = payload;
      }
      return indexName;

    default:
      return state;
  }
};

export const orderConfirmationSwitchReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_IS_ONLINE_ORDER_CONFIRMATION_FLOW:
      let switchValue = 0;
      if (payload) {
        switchValue = payload;
      }
      return switchValue;

    default:
      return state;
  }
};

export const decimalPointsReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_DECIMAL_POINTS:
      let switchValue = 0;
      if (payload) {
        switchValue = payload;
      }
      return switchValue;

    default:
      return state;
  }
};

export const enableWishlistReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.ENABLE_WISHLIST:
      let switchValue = 0;
      if (payload) {
        switchValue = payload;
      }
      return switchValue;

    default:
      return state;
  }
};

// profile details

export const fetchProfileDetailsReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.FETCH_PROFILE_DETAILS:
      return payload;

    default:
      return state;
  }
};
