import * as Yup from "yup";

export const signUpSchemas = Yup.object({
  customerFirstName:Yup.string()
  .matches(/^[a-zA-Z]+$/, "Enter Valid First Name")
  .min(2, "First Name should be 2 to 50 characters")
  .max(50, "First Name Should be upto 50 characters")
  .required("Please Enter Your First Name"), // function chaining

  customerLastName:Yup.string()
  .matches(/^[a-zA-Z]+$/, "Enter Valid Last Name")
  .min(2, "Last Name should be 2 to 50 characters")
  .max(50, "Last Name Should be upto 50 characters"),
  // .required("Please Enter Your Last Name"), // function chaining

  customerEmail:Yup.string()
  .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter Valid Email ID")
  .email("Enter valid Email ID"),
})