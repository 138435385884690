import styled from "styled-components";

export const NewSideBarCartContainer = styled.div`
  .sidebar-cart-parent {
    font-size: 16px;
    font-weight: 800;
    height: 100%;

    padding: 0px;

    .sidebar-cart-inner-parent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .sidebar-header {
      position: sticky;
      padding: 20px 0 0 0;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    /* background-color: red; */
    /* height: 100%;
    width: 100%; */
    .close-icon-parent {
      display: flex;
      justify-content: flex-end;
      /* margin: 20px 0 0 0; */
      /* border: red 1px solid; */
    }
    .no-of-items-and-clear-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px; // gap vertical
      /* border: red 1px solid; */
      .no-of-items-icon-and-no-of-itemIn-digits {
        /* border: red 1px solid; */
        display: flex;
        align-items: end;
        padding: 0 0 8px 0;
        gap: 5px;
        .no-of-items-icon {
        }
        .no-of-itemIn-digits {
          color: #777777;
          padding: 0px;
          line-height: 15px;
        }
      }
      .clear-all-button-parent {
        .clear-all-button {
          border: 1px solid grey;
          padding: 3px 15px;
          color: #707070;
          font-size: 14px;
          font-weight: 400;
          border-radius: 5px;
          background-color: white;
        }
      }
    }
  }

  .delete-icon-icon-parent {
    padding: 0 0 0 25px;
    cursor: pointer;
  }

  .footer-parent {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 1;

    .separator-footer {
      padding: 0px;
      margin: 0px;
      stroke-width: 0.6px;
      color: #707070;
      opacity: 0.4;
    }

    .total-text {
      padding: 20px 0 20px 0;
      color: #1c95d1;
      font-size: 20px;
      font-weight: 700;
    }

    .view-cart-and-checkout-cart-button {
      padding: 0 0 20px 0;
      display: flex;
      justify-content: space-between;

      .footer-button {
        display: block;
        width: 45%;
        color: #707070;
        font-size: 18px;
        font-weight: 500;
        padding: 5px 0;
        border-radius: 10px;
        border: solid 1px #707070;
      }
      .view-cart-button {
        background-color: white;
      }
      .checkout-cart-button {
        cursor: ${({ isOutOfStock }) => {
          return !isOutOfStock ? "pointer" : "not-allowed";
        }};
        color: #fff;
        background-color: #272626;
        opacity: ${({ isOutOfStock }) => {
          return !isOutOfStock ? 1 : 0.6;
        }};
      }
    }
  }

  .separator {
    stroke-width: 0.6px;
    color: #707070;
    opacity: 0.4;
    margin-top: 19px;
  }
`;
