import styled from "styled-components";

export const AccessDeniedPageContainer = styled.div`
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 20px;
    padding: 10px;
    img {
      width: 50%;
      height: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 200px;
    }
  }
`;
