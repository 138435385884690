import React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteIcon2 } from "../../pages/checkout/icons/checkoutPageIcons";
import { defaultImage } from "../../utils/productUtils/ProductUtils";
import { NewSideBarCartItemContainer } from "./NewSideBarCartItemContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  AddToCartBtnHandler,
  RemoveFromCartEventHandler,
  RemoveQtyFromCartBtnHandler,
  setSelectedProductEventHandler,
} from "../../utils/globalFunctions/cartFunctions";
const NewSideBarCartItem = ({
  key,
  cartItem,
  ShowHideSideBarCartComponentEventHandler,
}) => {
  const navigate = useNavigate();
  const redirectToAnotherRoute = (path) => {
    navigate(path);
  };
  const isOutOfStock = !(
    cartItem.availableQuantity <= 0 && cartItem.activeOnline === 1
  );

  const dispatch = useDispatch();
  const decimalPoints = useSelector((state) => state.decimalPoints);
  const availableQty = cartItem.availableQuantity > 0;
  const activeOnline = cartItem.activeOnline == 1;
  console.log("cartItem.activeOnline=====>", cartItem.activeOnline);
  return (
    <NewSideBarCartItemContainer
      isOutOfStock={isOutOfStock && cartItem.activeOnline}
    >
      <div className="cart-item-parent">
        {/* item image */}
        <div
          className="cartItem-image-parent"
          onClick={() => {
            setSelectedProductEventHandler(
              cartItem.productid,
              dispatch,
              cartItem.productVarientId
            );
            redirectToAnotherRoute(`/productView/${cartItem.productid}`);
          }}
        >
          <img
            src={cartItem.imgSrc || defaultImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage;
            }}
            alt=""
            className="itemImg"
          />
        </div>
        {/* item name */}
        <div className="itemName-row-container">
          <div className="item-name-and-remove-item">
            <div
              className="itemName"
              onClick={() => {
                setSelectedProductEventHandler(
                  cartItem.productid,
                  dispatch,
                  cartItem.productVarientId
                );
                redirectToAnotherRoute(`/productView/${cartItem.productid}`);
              }}
            >
              {" "}
              {cartItem.productName}
            </div>
            <div
              className="delete-icon-icon-parent"
              onClick={() =>
                RemoveFromCartEventHandler(cartItem.productVarientId, dispatch)
              }
            >
              <DeleteIcon2 />
            </div>
          </div>
          <div className="item-variant-and-Stock">
            {!!cartItem?.variantName?.length > 0 && (
              <span className="item-variant">{cartItem.variantName}</span>
            )}
            {/* {
              cartItem.availableQuantity <= 0 &&
                cartItem.activeOnline === 1 && (
                  <span className="out-of-stock stock-text">Out Of Stock</span>
                )
              // : (
              //   cartItem.activeOnline !== 0 && (
              //     <span className="in-stock stock-text">In Stock</span>
              //   )
              // )
            } */}
            
          </div>
          <div className="mrp-parent-and-qty-button">
            <div className="mrp-parent">
              <span className="mrp-text">
                ₹{parseFloat(cartItem?.price).toFixed(2)}
              </span>
            </div>
            {!(
              cartItem.availableQuantity <= 0 && cartItem.activeOnline === 1
            ) && (
              <div className="qty-button-parent">
                <div className="quantity-button-parent">
                  <button
                    className="remove-item-button"
                    onClick={() =>
                      RemoveQtyFromCartBtnHandler(
                        cartItem.productVarientId,
                        dispatch
                      )
                    }
                  >
                    -
                  </button>
                  <span
                    className="item-qty"
                    style={{
                      margin: "0 8px",
                      fontSize: "15px",
                      minWidth: "19px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {cartItem.quantity}
                  </span>
                  <button
                    className="add-item-button"
                    disabled={!isOutOfStock}
                    onClick={() =>
                      AddToCartBtnHandler(
                        cartItem.productVarientId,
                        cartItem.price,
                        cartItem.imgSrc,
                        cartItem.productName,
                        cartItem.productid,
                        cartItem.availableQuantity,
                        cartItem.variantName,
                        { activeOnline: 1 },
                        dispatch
                      )
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            )}
            {
              !(isOutOfStock) && (
                <span className="out-of-stock stock-text">Out Of Stock</span>
              )
              // : (
              //   cartItem.activeOnline !== 0 && (
              //     <span className="in-stock stock-text">In Stock</span>
              //   )
              // )
            }
            {cartItem.activeOnline === 0 && (
              <span className="out-of-stock stock-text" style={{fontSize:"13px", textAlign:"center"}}>Currently Unavailable</span>
            )}
          </div>
        </div>

        {/*<div className="delete-icon-and-qty-button">
           <div
            className="delete-icon-icon-parent"
            onClick={() =>
              RemoveFromCartEventHandler(cartItem.productVarientId, dispatch)
            }
          >
            <DeleteIcon2 />
          </div> */}
        {/* <div className="qty-button-parent">
            <div className="quantity-button-parent">
              <button
                className="remove-item-button"
                onClick={() =>
                  RemoveQtyFromCartBtnHandler(
                    cartItem.productVarientId,
                    dispatch
                  )
                }
              >
                -
              </button>
              <span
                className="item-qty"
                style={{ margin: "0 8px", fontSize: "15px", minWidth: "19px", textAlign:"center" }}
              >
                {cartItem.quantity}
              </span>
              <button
                className="add-item-button"
                disabled={!isOutOfStock}
                onClick={() =>
                  AddToCartBtnHandler(
                    cartItem.productVarientId,
                    cartItem.price,
                    cartItem.imgSrc,
                    cartItem.productName,
                    cartItem.productid,
                    cartItem.availableQuantity,
                    cartItem.variantName,
                    { activeOnline: 1 },
                    dispatch
                  )
                }
              >
                +
              </button>
            </div>
          </div> 
        </div>*/}
      </div>
    </NewSideBarCartItemContainer>
  );
};

export default NewSideBarCartItem;
