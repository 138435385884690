import  {ActionTypes}  from "../constants/ActionTypes";
const intialState = [];
const defaultCategoryTotalRecordsCount = 0;

export const brandReducers = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BRAND:
      return payload;
      
    default:
      return state;
  }

  
};

export const brandTotalRecordsCountReducers = (state = defaultCategoryTotalRecordsCount, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BRAND_TOTAL_COUNT:
      return payload;
    default:
      return state;
  }
};

