import { ActionTypes } from "../constants/ActionTypes";
import "react-toastify/dist/ReactToastify.css";
import { TOASTER_MESSAGES } from "../../utils/Constants";
import Swal from "sweetalert2";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";

export const AddToCart =
  (
    productVarientId,
    price,
    imgSrc,
    productName,
    productid,
    quantity,
    variantName,
    activeOnline
  ) =>
  (dispatch, getState) => {
    const cartItems = getState().cartItems;
    let avlqty = 0;
    try {
      avlqty = parseFloat(quantity);
    } catch (error) {
      avlqty = parseFloat(0);
    }
    let newCattItem = [...cartItems];
    let alreadyExists = false;

    for (const cartItem of newCattItem) {
      if (cartItem.productVarientId === productVarientId) {
        alreadyExists = true;
        if (cartItem.quantity >= 15 || cartItem.quantity === avlqty) {
          if (cartItem.quantity >= 15) {
            globalSwalFunction(
              "Maximum limit reached!",
              '<i class="fas fs-5 fa-check-circle text-success "></i>'
            );
          } else {
            globalSwalFunction(
              "Sorry, we don’t have any more units for this item!",
              '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
            );
          }
        } else {
          cartItem.quantity++;
          cartItem.availableQuantity = quantity;
          globalSwalFunction(
            `${TOASTER_MESSAGES.ADD_TO_CART_MESSAGE}`,
            '<i class="fas fs-5 fa-check-circle text-success "></i>'
          );
        }
        break;
      }
    }
    if (!alreadyExists) {
      newCattItem.push({
        productVarientId: productVarientId,
        price: parseFloat(price).toFixed(6),
        quantity: 1,
        imgSrc: imgSrc,
        productName: productName,
        productid: productid,
        isFromBuyNow: 0,
        availableQuantity: quantity ? parseInt(quantity) : 0,
        variantName: variantName,
        activeOnline: activeOnline,
      });
    }

    dispatch({ type: ActionTypes.ADD_TO_CART, payload: newCattItem });
  };

export const RemoveFromCart = (productVarientId) => (dispatch, getState) => {
  const cartItems = getState().cartItems.filter(
    (x) => x.productVarientId !== productVarientId
  );

  const newCattItem = [...cartItems];
  localStorage.setItem("cartItems", JSON.stringify(newCattItem));

  dispatch({ type: ActionTypes.REMOVE_TO_CART, payload: newCattItem });
};

export const RerenderCartItems = (cartItems) => (dispatch, getState) => {
  const newCattItem = [...cartItems];
  localStorage.setItem("cartItems", JSON.stringify(newCattItem));
  dispatch({ type: ActionTypes.SET_CART_ITEMS_AGAIN, payload: newCattItem });
};

export const AddQtyIntoCart = (id) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems;
  const newCattItem = [...cartItems];
  for (const cartItem of newCattItem) {
    if (cartItem.product_varient_id === id) {
      cartItem.count++;
      break;
    }
  }

  localStorage.setItem("cartItems", JSON.stringify(newCattItem));
  dispatch({ type: ActionTypes.ADD_QTY_INTO_CART, payload: newCattItem });
};

export const RemoveQtyFromCart = (id) => (dispatch, getState) => {
  var cartItems = getState().cartItems;
  let newCattItem = [...cartItems];
  for (const cartItem of newCattItem) {
    if (cartItem.productVarientId === id) {
      if (cartItem.quantity > 1) {
        cartItem.quantity--;
      } else if (cartItem.quantity <= 1) {
        newCattItem = getState().cartItems.filter(
          (x) => x.productVarientId !== id
        );
      }
      break;
    }
  }
  localStorage.setItem("cartItems", JSON.stringify(newCattItem));
  dispatch({ type: ActionTypes.REMOVE_QTY_FROM_CART, payload: newCattItem });
};

export const AdjustQty = (productId, qty) => {
  return {
    type: ActionTypes.REMOVE_TO_CART,
    payload: {
      productId: productId,
      qty: qty,
    },
  };
};

export const LoadCurrentItem = (product) => {
  return {
    type: ActionTypes.REMOVE_TO_CART,
    payload: product,
  };
};

export const populateCartItemsIntoCart = () => (dispatch, getState) => {
  let cartItems = getState().cart.cartItems;
  dispatch({ type: ActionTypes.POPULATE_CART_ITEM, payload: cartItems });
};

export const ClearCart = (payload) => {
  return {
    type: ActionTypes.CLEAR_CART,
    payload: payload || [],
  };
};

export const ClearBuyNowCart = () => {
  return {
    type: ActionTypes.CLEAR_CART_FOR_BUY_NOW,
    payload: [],
  };
};
export const AddToCartForBuyNow =
  (
    productVarientId,
    price,
    imgSrc,
    productName,
    productid,
    quantity,
    variantName,
    activeOnline
  ) =>
  async (dispatch, getState) => {
    let cartItems = await getState().cartItems;
    let newCattItem = [...cartItems];
    let newCartItemForBuyNow = [];
    let alreadyExists = false;

    newCartItemForBuyNow.push({
      productVarientId: productVarientId,
      price: parseFloat(price).toFixed(6),
      quantity: 1,
      imgSrc: imgSrc,
      productName: productName,
      productid: productid,
      isFromBuyNow: 1,
      availableQuantity: quantity ? parseInt(quantity) : 0,
      variantName: variantName,
      activeOnline: activeOnline,
    });

    for (const cartItem of newCattItem) {
      if (cartItem.productVarientId === productVarientId) {
        alreadyExists = true;

        if (cartItem.quantity >= 15) {
          //notifyToast("you can't add more than 15 cartItems.",'error');
        } else {
          // cartItem.quantity++;
        }
        break;
      }
    }
    if (!alreadyExists) {
      const temp = {
        productVarientId: productVarientId,
        price: parseFloat(price).toFixed(6),
        quantity: 1,
        imgSrc: imgSrc,
        productName: productName,
        productid: productid,
        isFromBuyNow: 1,

        availableQuantity: quantity ? parseInt(quantity) : 0,
        variantName: variantName,
        activeOnline: activeOnline,
      };
      newCattItem = [...newCattItem, temp];
    }

    localStorage.setItem(
      "cartItemsForBuyNow",
      JSON.stringify(newCartItemForBuyNow)
    );

    dispatch({
      type: ActionTypes.ADD_TO_CART_FOR_BUY_NOW,
      payload: newCartItemForBuyNow,
    });

    localStorage.setItem("cartItems", JSON.stringify(newCattItem));
    dispatch({ type: ActionTypes.ADD_TO_CART, payload: newCattItem });
  };

export const AddToCartReorder =
  (
    productVarientId,
    price,
    imgSrc,
    productName,
    productid,
    quantity,
    availableQuantity,
    variantName,
    activeOnline
  ) =>
  (dispatch, getState) => {
    const cartItems = getState().cartItems;
    let newCattItem = [...cartItems];
    let alreadyExists = false;
    for (const cartItem of newCattItem) {
      if (cartItem.productVarientId === productVarientId) {
        alreadyExists = true;
        if (cartItem.quantity >= availableQuantity) {
          cartItem.quantity = availableQuantity;
          cartItem.availableQuantity = availableQuantity;
        } else {
          cartItem.quantity = quantity;
          cartItem.availableQuantity = availableQuantity;
          Swal.fire({
            text: `${TOASTER_MESSAGES.ADD_TO_CART_MESSAGE}`,
            iconHtml: '<i class="fas fs-5 fa-check-circle text-success "></i>',
            target: "#custom-target",
            showConfirmButton: false,
            showClass: {
              popup: "animate__animated animate__slideInUp",
            },
            //   hideClass: {
            //     popup: 'animate__animated animate__fadeInDown'
            //   },
            timer: 2000,
            timerProgressBar: true,
            customClass: {
              container: "position-absolute",
            },
            toast: true,
            position: "bottom-right",
          });
        }
        break;
      }
    }
    if (!alreadyExists) {
      newCattItem.push({
        productVarientId: productVarientId,
        price: parseFloat(price).toFixed(6),
        quantity: quantity,
        imgSrc: imgSrc,
        productName: productName,
        productid: productid,
        isFromBuyNow: 0,
        availableQuantity: availableQuantity ? parseInt(availableQuantity) : 0,
        variantName: variantName,
        activeOnline: activeOnline
      });
    }
    localStorage.setItem("cartItems", JSON.stringify(newCattItem));
    dispatch({ type: ActionTypes.ADD_TO_CART, payload: newCattItem });
  };

export const RemoveFromActualCartIfIsFromBuyNow =
  () => (dispatch, getState) => {
    const cartItems = getState().cartItems.filter((x) => x.isFromBuyNow !== 1);
    const newCattItem = [...cartItems];
    localStorage.setItem("cartItems", JSON.stringify(newCattItem));
    dispatch({ type: ActionTypes.ADD_TO_CART, payload: newCattItem });
  };
