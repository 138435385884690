import Swal from "sweetalert2";

export const globalSwalFunction = (text, iconHtml, options) => {
  const result = Swal.fire({
    text: text,
    iconHtml: iconHtml,
    target: "#custom-target",
    showConfirmButton: false,
    showClass: {
      popup: "animate__animated animate__slideInUp",
    },
    timer: 2000,
    timerProgressBar: true,
    customClass: {
      container: "position-absolute",
    },
    toast: true,
    position: "bottom-right",
    ...options,
  });

  return result;
};

export const deleteSwalFunction = async (
  title,
  text,
  confirmButtonText,
  options
) => {
  const result = await Swal.fire({
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText,
    ...options,
  });

  return result;
};
