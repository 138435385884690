import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../Constants";
export const findServiciblityareaFnc = async (
  mainArrray,
  latitude,
  longitude,
  userPincode
) => {
  const area_serviciblity_type_pincode = "pincode";
  const area_serviciblity_type_radius = "radius";
  const area_serviciblity_type_geofencing = "geofencing";
  // const userPincode = "380059";
  let finalServiciblityAreaArray = [];
  let pincodeArray = mainArrray.filter(
    (el) => el.area_serviciblity_type === area_serviciblity_type_pincode
  );
  let radiusArray = mainArrray.filter(
    (el) => el.area_serviciblity_type === area_serviciblity_type_radius
  );
  let geofencingArray = mainArrray.filter(
    (el) => el.area_serviciblity_type === area_serviciblity_type_geofencing
  );
   console.log({ pincodeArray, radiusArray, geofencingArray }, "pinrad");
  if (geofencingArray.length > 0) {
    try {
      const parsedGeoData = geofencingArray.map((data) => {
        // Parse the areaCoordinates and create a new object
        const parsedCoordinates = JSON.parse(data.area_coordinates).map(
          (coords) =>
            coords.map((coord) => ({
              lat: coord.lat,
              lng: coord.lng,
            }))
        );

        // Create a new object with the parsed coordinates and the rest of the properties
        const result = {
          address: data.address,
          api_token: data.api_token,
          area_coordinates: data.area_coordinates,
          area_id: data.area_id,
          area_name: data.area_name,
          area_serviciblity_id: data.area_serviciblity_id,
          area_serviciblity_type: data.area_serviciblity_type,
          branch_id: data.branch_id,
          branch_lat: data.branch_lat,
          branch_lng: data.branch_lng,
          branch_name: data.branch_name,
          company_id: data.company_id,
          is_active: data.is_active,
          area_coordinates: parsedCoordinates,
          is_assigned: data.is_assigned,
          is_branch_serviciblity: data.is_branch_serviciblity,
          pincodes: data.pincodes,
          radius: data.radius,
          status: data.status,
        };

        return result;
      });

      const userFallingArray = parsedGeoData.filter((polygonArray) => {
        const areaCoords = new window.google.maps.Polygon({
          paths: polygonArray.area_coordinates,
        });

        const resultPath = window.google.maps.geometry.poly.containsLocation(
          { lat: latitude, lng: longitude },
          areaCoords
        );

        if (resultPath) {
          return polygonArray;
        }
      });

      let shortestDistance = Infinity;
      let nearestBranch = null;

      for (const geoFencingBranchVo of userFallingArray) {
        const distanceFromUserToCurrentBranch = await calculateDistance(
          {
            lat: Number(geoFencingBranchVo.branch_lat),
            lng: Number(geoFencingBranchVo.branch_lng),
          },
          latitude,
          longitude
        );

        if (distanceFromUserToCurrentBranch < shortestDistance) {
          shortestDistance = distanceFromUserToCurrentBranch;
          nearestBranch = geoFencingBranchVo;
        }
      }

      if (nearestBranch) {
        finalServiciblityAreaArray.push(nearestBranch);
      }
    } catch (error) {
      console.error("Error in geofencing detection.", error);
    }
  }

  if (pincodeArray.length > 0) {
    pincodeArray.forEach((pincodeBranchVo) => {
      if (pincodeBranchVo.pincodes) {
        let pincodes = pincodeBranchVo.pincodes.split(",");
        if (pincodes.includes(userPincode)) {
          finalServiciblityAreaArray.push(pincodeBranchVo);
        }
      }
    });
  }

  if (radiusArray.length > 0) {
    try {
      for (const radiusBranchVo of radiusArray) {
        const distancefromUserToCurrentBranch = await calculateDistance(
          {
            lat: Number(radiusBranchVo.branch_lat),
            lng: Number(radiusBranchVo.branch_lng),
          },
          latitude,
          longitude
        );
        console.log(
          radiusBranchVo.branch_name,
          distancefromUserToCurrentBranch / 1000,
          "<<>>"
        );
        const radiusInKm = radiusBranchVo.radiusUnit === "km" ?  radiusBranchVo.radius :  radiusBranchVo.radius / 1000
        if (distancefromUserToCurrentBranch / 1000 <= radiusInKm) {
          finalServiciblityAreaArray.push(radiusBranchVo);
        }
      }
    } catch (error) {
      console.error("Error calculating distances:", error);
    }
  }
  console.log(finalServiciblityAreaArray, "<<finalServiciblityAreaArray>>");
  if (finalServiciblityAreaArray.length > 0) {
    let actualServiciblityAreaArrayWithDistace = await Promise.all(
      finalServiciblityAreaArray.map(async (servicibleBranchVo) => {
        const distance = await calculateDistance(
          {
            lat: Number(servicibleBranchVo.branch_lat),
            lng: Number(servicibleBranchVo.branch_lng),
          },
          latitude,
          longitude
        );
        return {
          ...servicibleBranchVo,
          distance: distance,
        };
      })
    );

    actualServiciblityAreaArrayWithDistace.sort(
      (a, b) => a.distance - b.distance
    );

    if (actualServiciblityAreaArrayWithDistace.length > 0) {
      return actualServiciblityAreaArrayWithDistace[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

//Converts coordinates in degrees to radians.
function toRad(degrees) {
  return (degrees * Math.PI) / 180;
}

const service = new window.google.maps.DistanceMatrixService();

const calculateDistance = (destination, latitude, longitude) => {
  return new Promise((resolve, reject) => {
    const origin = { lat: latitude, lng: longitude };
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          let distance = response.rows[0].elements[0].distance.value;
          resolve(distance); // Resolve the Promise with the distance value
        } else {
          console.error("Error calculating distance:", status);
          reject(status); // Reject the Promise with the error status
        }
      }
    );
  });
};
//value will be the address of any branch
export const findCoords = (value) => {

  return new Promise((resolve, reject) => {
    Geocode.setApiKey(GOOGLE_API_KEY);

    Geocode.fromAddress(value).then(
      (response) => {
        let coordsObj = {
          lat: response.results[0].geometry.location.lat,
          lng: response.results[0].geometry.location.lng,
        };
        resolve(coordsObj);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
