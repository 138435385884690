/**
 * It checks if a product is already added into the cart or not
 * @param productId - The product id of the product you want to check.
 * @param cartItems - This is the array of objects that contains the cart items.
 */
export const checkVariationProductAlreadyAddedIntoCart = (
  productId,
  cartItems
) => {
  let filteredCartArray = cartItems.filter((x) => x.productid === productId);
  if (filteredCartArray.length > 0) {
    return true;
  } else {
    return false;
  }
};

// Default Image url
export const defaultImage = "/icons/default-image.svg";
export const defaultCategory = "/icons/defaultCategory.svg"

// Default ReactLoading Loader Color
export const LoaderColor = `var(--primary-color)`;
