import { ActionTypes } from '../constants/ActionTypes';

export const getbrandaction = (id) => async (dispatch, getState) => {

  const brandFilter = getState().brandFilter

  let newArray = [...brandFilter];
  if (newArray.includes(id)) {
    newArray = [...newArray.filter((x) => (x !== id))];
  } else {
    newArray.push(id);
  }

  dispatch({ type: ActionTypes.SET_BRANDS_FILTER, payload: newArray });



};

export const clearBrandAction = () => async (dispatch, getState) => {
  await dispatch({ type: ActionTypes.SET_BRANDS_FILTER, payload: [] });
};