import {
  AddToCart,
  ClearCart,
  RemoveFromCart,
  RemoveQtyFromCart,
} from "../../State/actions/addToCartActions";
import { selectedProduct } from "../../State/actions/productActions";
import { RELATEABLE_DIV_SHOW_ON_QUICKVIEW } from "../Constants";
import { checkVariationProductAlreadyAddedIntoCart } from "../productUtils/ProductUtils";
import { deleteSwalFunction, globalSwalFunction } from "./globalSwal";
import Swal from "sweetalert2";
// Add to cart functionality- Without variants
export const AddToCartBtnHandler = (
  productVarientId,
  price,
  imgSrc,
  productName,
  productid,
  quantity,
  variantName,
  { activeOnline },
  dispatch
) => {
  dispatch(
    AddToCart(
      productVarientId,
      price,
      imgSrc,
      productName,
      productid,
      quantity,
      variantName,
      activeOnline
    )
  );
};

// Remove from cart functionality- Without variants
export const RemoveQtyFromCartBtnHandler = (productVarientId, dispatch) => {
  dispatch(RemoveQtyFromCart(productVarientId));
};

// Remove all products from cart Func.
export const RemoveFromCartEventHandler = (productVarientId, dispatch) => {
  dispatch(RemoveFromCart(productVarientId));
};

// Event handler- IF Product have variants-Add To Cart-(Re-factor)
export const handleAddToCartWrapperFnc = (
  productItemObj,
  cartItems,
  setSelectedProductEventHandler,
  setRelatedDiv,
  triggerQuickViewModal,
  id,
  productType,
  productImage,
  defaultImage,
  checkProductQtyWithVariantStock,
  currentProduct,
  dispatch,
  isShowModel = true
) => {
  const { havevariants, productid } = productItemObj;

  if (havevariants === 1 && isShowModel) {
    setSelectedProductEventHandler(
      productid,
      dispatch,
      !!productItemObj?.productvarientid ? productItemObj?.productvarientid : ""   // added for VC-729 
    );
    const productAlreadyAdded = checkVariationProductAlreadyAddedIntoCart(
      productid,
      cartItems
    );

    setTimeout(() => {
      setRelatedDiv(
        RELATEABLE_DIV_SHOW_ON_QUICKVIEW[
          productAlreadyAdded ? "REPEAT_LAST_VARINATS" : "ADD_PRODUCT_VARIANTS"
        ]
      );
      triggerQuickViewModal("open", id);
    }, 200);
  } else {
    handleAddToCartAnimationEvent(
      productType + `homeProduct` + productItemObj.productid,
      productImage ? productImage : defaultImage,
      "",
      // variables
      checkProductQtyWithVariantStock,
      currentProduct,
      productItemObj,
      dispatch
    );
  }
};

// Event handler- IF Product have variants-Remove From Cart-(Re-factor)
export const RemoveQtyFromCartBtnWrapperFnc = (
  productItemObj,
  cartItems,
  setSelectedProductEventHandler,
  setRelatedDiv,
  triggerQuickViewModal,
  dispatch,
  isShowModel
) => {
  const { havevariants, productid, productvarientid } = productItemObj;

  if (havevariants === 1 && isShowModel) {
    try {
      const filteredCartArray = cartItems.filter(
        (item) => item.productid === productItemObj.productid
      );

      if (filteredCartArray.length > 1) {
        setSelectedProductEventHandler(
          productid,
          dispatch,
          !!productItemObj?.productvarientid
            ? productItemObj?.productvarientid
            : ""
        );
        setTimeout(() => {
          setRelatedDiv(
            RELATEABLE_DIV_SHOW_ON_QUICKVIEW.REMOVEPRODUCT_VARIANTS
          );
          triggerQuickViewModal("open");
        }, 200);
      } else if (filteredCartArray.length === 1) {
        RemoveQtyFromCartBtnHandler(
          filteredCartArray[0].productVarientId,
          dispatch
        );
      } else {
        RemoveQtyFromCartBtnHandler(productvarientid, dispatch);
      }
    } catch (error) {
      RemoveQtyFromCartBtnHandler(productvarientid, dispatch);
    }
  } else {
    RemoveQtyFromCartBtnHandler(productvarientid, dispatch);
  }
};

// Animation Event Handler on Add To Cart Functionality...
export const handleAddToCartAnimationEvent = (
  id,
  imgSrc,
  variantName,
  // In the component variables
  checkProductQtyWithVariantStock,
  currentProduct,
  productItemObj,
  dispatch
) => {
  let isAnimation = false;
  if (checkProductQtyWithVariantStock) {
    // This function created for fire toaster with swal (Third party Dependency)
    globalSwalFunction(
      `Added into Cart...!!!`,
      '<i class="fas fs-5 fa-check-circle text-success "></i>'
    );
    if (currentProduct) {
      if (
        currentProduct.quantity >= 15 ||
        currentProduct.quantity === currentProduct.availableQuantity
      ) {
        isAnimation = false;
        if (currentProduct.quantity >= 15) {
          // This function created for fire toaster with swal (Third party Dependency)
          globalSwalFunction(
            "Maximum limit reached!",
            '<i class="fas fs-5 fa-check-circle text-success "></i>'
          );
        } else {
          // This function created for fire toaster with swal (Third party Dependency)
          globalSwalFunction(
            "Sorry, we don’t have any more units for this item!",
            '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
          );
        }
      } else {
        isAnimation = true;
      }
    } else {
      isAnimation = true;
    }
  } else {
    isAnimation = false;
  }

  if (isAnimation) {
    // removing animation as of now

    // let imgtodrag = document.getElementById(id);
    // let viewcartDesktop = document.getElementsByClassName("cd-cart-trigger")[0];
    // let viewCartMobile = document.getElementsByClassName(
    //   "mobile-cart-trigger"
    // )[0];
    // let imgtodragImage = imgtodrag.querySelector(".pro-image-front");

    // let viewcart;

    // if (window.innerWidth <= 900) {
    //   viewcart = viewCartMobile;
    // } else {
    //   viewcart = viewcartDesktop;
    // }
    // try {
    //   let disLeft = imgtodrag.getBoundingClientRect().left;
    //   let disTop = imgtodrag.getBoundingClientRect().top;
    //   let cartleft = viewcart.getBoundingClientRect().left;
    //   let carttop = viewcart.getBoundingClientRect().top;
    //   let image = imgtodragImage.cloneNode(true);

    //   image.style =
    //     "z-index: 11; width: 100px;opacity:0.8; position:fixed; top:" +
    //     disTop +
    //     "px;left:" +
    //     disLeft +
    //     "px;transition: left 1s, top 1s, width 1s, opacity 1s cubic-bezier(1, 1, 1, 1)";
    //   var rechange = document.body.appendChild(image);
    //   setTimeout(function () {
    //     image.style.left = cartleft + "px";
    //     image.style.top = carttop + "px";
    //     image.style.width = "20px";
    //     image.style.opacity = "0";
    //   }, 200);
    //   setTimeout(function () {
    //     rechange.parentNode.removeChild(rechange);
    //   }, 1500);
    // } catch (error) {
    //   console.log(error);
    // }

    AddToCartBtnHandler(
      productItemObj.productvarientid,
      productItemObj.sellingprice,
      imgSrc,
      productItemObj.productname || productItemObj.name,
      productItemObj.productid,
      productItemObj.quantity,
      variantName,
      { activeOnline: 1 },
      dispatch
    );
  } else {
    if (productItemObj.quantity <= 0) {
      // This function created for fire toaster with swal (Third party Dependency)
      globalSwalFunction(
        "Sorry, we don’t have any more units for this item!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
    }
  }
};

// Select Specific product dispatcher...
export const setSelectedProductEventHandler = (
  productId,
  dispatch,
  productVarientId = ""
) => {
  dispatch(selectedProduct(productId, productVarientId));
};

// clear cart function...
export const ClearCartEventHandler = async (
  ShowHideSideBarCartComponentEventHandler,
  dispatch
) => {
  const result = await deleteSwalFunction(
    "Remove Item",
    "Are you sure you want to remove this item?",
    "Delete"
  );

  if (result.isConfirmed) {
    globalSwalFunction(
      "successfully removed items from your cart!",
      '<i class="fas fs-5 fa-check-circle text-success "></i>'
    );
    dispatch(ClearCart());
    ShowHideSideBarCartComponentEventHandler();
  }
};

export const ClearQtyForMyCartPageEventHandler = async (
  productVarientId,
  dispatch
) => {
  const result = await deleteSwalFunction(
    "Remove Item",
    "Are you sure you want to remove this item?",
    "Remove"
  );

  if (result.isConfirmed) {
    Swal.fire({
      icon: "success",
      title: "Successfully removed item from your cart",
      showConfirmButton: false,
      timer: 1500,
    });
    dispatch(RemoveFromCart(productVarientId));
  }
};
