import React, { useState } from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../../PrimaryButton";
import { useEffect } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const LoginDialogueModal = ({
  isLoading,
  MobileNo,
  setMobileNo,
  sendOtp,
  showLoginOtpModalEventHandler,
  handleKeyPress,
  error,
  errorMessage,
  checkAnyCMSPagesFoundOrNotForOTPModal,
  sendOtpMessage,
}) => {
  const mobileChangeHandler = (e) => {
    setMobileNo(e.target.value.replace(/[^0-9]/g, ""));
  };

  let termsAndConditions = 0;
  let privacyPolicy = 0;

  useEffect(() => {
    let cmsPage = checkAnyCMSPagesFoundOrNotForOTPModal().termsAndConditions;
  }, []);

  // console.log("errorMessage", errorMessage, error);

  return (
    <div
      className="form-container sign-in-container mobileNumberRequest"
      style={{ display: "block" }}
    >
      <div
        className="accountCredFormClose"
        onClick={() => showLoginOtpModalEventHandler("close")}
      >
        <i className="fas fa-times"></i>
      </div>
      <div className="form">
        <div className="welcomeBack_container">
          <h1 className="fs-5 fw-bolder lh-base welcomeBack">Sign in</h1>
        </div>
        <div className="get_started_container">
          <h1 className="d-flex fs-6 fw-bolder lh-base get_started">
            Get started with VasyERP
          </h1>
          <img src="/images/loginImage.svg" />
        </div>
        <label className="signInLabel d-flex mb-1">
          {/* <img src="/images/india.png" /> */}
          <span className="d-flex align-items-center ps-1 signInLabelSpan">
            +91
          </span>
          <input
            className="telInput"
            maxLength={10}
            placeholder="Mobile Number*"
            required
            value={MobileNo}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => {
              mobileChangeHandler(e);
            }}
            id="mobileNoInput"
          />
        </label>
        {(error || sendOtpMessage) && (
          <span
            className="text-danger mb-1 "
            style={{ fontWeight: "bold", textAlign: "left", display: "flex" }}
          >
            <WarningAmberIcon />
            {error ? errorMessage : sendOtpMessage}
          </span>
        )}

        <p className="signInTerms">
          By continuing, you agree to VasyERP's{" "}
          {checkAnyCMSPagesFoundOrNotForOTPModal().termsAndConditions ? (
            <Link to="/terms">Terms of Use</Link>
          ) : (
            "Terms of Use"
          )}{" "}
          and{" "}
          {checkAnyCMSPagesFoundOrNotForOTPModal().privacyPolicy ? (
            <Link to="/privacy">Privacy Policy.</Link>
          ) : (
            "Privacy Policy."
          )}
        </p>
        <PrimaryButton
          id="requestOTP"
          name="Request OTP"
          type="button"
          className="requestOTP accountCredPopupBtn"
          onClick={() => sendOtp("newOtp")}
          icon={
            <i
              className={isLoading ? "fa fa-spinner fa-spin" : ""}
              style={{ marginRight: "5px" }}
            ></i>
          }
        />
      </div>
    </div>
  );
};

export default LoginDialogueModal;
