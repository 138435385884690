import { ActionTypes } from "../constants/ActionTypes";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { ReactProjectBaseUrl } from "../../utils/Constants";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { getApiToken } from "../../utils/get-token";

export const AddToWishlist =
  (
    productvarientId,
    productId,
    categoryId,
    productName,
    productImage,
    mrp,
    sellingPrice,
    haveVariations
  ) =>
  async (dispatch) => {
    try {
      const token = getApiToken()
      const userId = Cookies.get("ID");
      const res = await axiosFunction(
        token,
        ReactProjectBaseUrl + API_ENDPOINTS.WISHLIST,
        "POST",
        {
          productVarientId: `${productvarientId}`,
          productId: `${productId}`,
          categoryId: `${categoryId}`,
          brandId: 0,
          productName: `${productName}`,
          image: `${productImage}`,
          mrp: `${mrp}`,
          sellingPrice: `${sellingPrice}`,
          contactId: `${userId}`,
          haveVariations: `${haveVariations}`,
        }
      );

      if (res?.data && res?.data?.status) {
        dispatch(GetWishlist(userId));
      }
    } catch (e) {
      throw new Error(e);
    }
  };

export const GetWishlist = (contactId) => async (dispatch) => {
  const token = getApiToken()
  let response = [];
  try {
    const res = await axiosFunction(
      token,
      ReactProjectBaseUrl +
        API_ENDPOINTS.FETCH_WISHLIST +
        "?contactId=" +
        contactId,
      "GET"
    );

    if (res?.data && res?.data?.status) {
      response = res?.data?.response;
    } else {
      response = [];
    }
    const newWishListArray = [...response];
    dispatch({ type: ActionTypes.FETCH_WISHLIST, payload: newWishListArray });
  } catch (error) {
    dispatch({ type: ActionTypes.FETCH_WISHLIST, payload: [] });
  }
};

export const RemoveFromWishlist =
  (contactId, wishlistId) => async (dispatch) => {
    try {
      const token = getApiToken()
      const userId = Cookies.get("ID");
      const res = await axiosFunction(
        token,
        ReactProjectBaseUrl +
          API_ENDPOINTS.DELETE_WISHLIST +
          "?wishllistId=" +
          wishlistId +
          "&wishlistId=" +
          contactId,
        "GET"
      );

      if (res?.data && res?.data?.response) {
        dispatch(GetWishlist(userId));
      }
    } catch (error) {
      console.error(error);
    }
  };
