import Cookies from 'js-cookie';

export const logout = () => {
    Cookies.remove('ID');
    Cookies.remove('CONTACT_NUMBER');
    Cookies.remove('CUSTOMER_NAME');
    Cookies.remove('CONTACT_EMAIL');
    Cookies.remove('CUSTOMER_FIRST_NAME');
    Cookies.remove('CUSTOMER_LAST_NAME');
    return true;
};