import Cookies from "js-cookie";

export const getToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("auth_token");
};

export const getCompanyId = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("companyId");
};

export const getBranchId = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("branchId");
};

export const getApiToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("API_TOKEN");
};

export const getBranchToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("BRANCH_TOKEN");
};

export const isGeofenchingEnabled = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("isGeofenchingEnabled");
};
