import { useState } from "react";
import { axiosFunction } from "../utils/globalFunctions/axiosFunction";
import { ReactProjectBaseUrl } from "../utils/Constants";
import { getApiToken } from "../utils/get-token";

const useCmsPage = () => {
  const [fetchData, setFetchData] = useState("");
  const token = getApiToken()
  const fetchCmsPage = (type) => {
    axiosFunction(token,`${ReactProjectBaseUrl}/cmsPages/fetchbyType/${type}`, "GET")
      .then((res) => setFetchData(res?.data?.response[0]?.cmsPageContent))
      .catch((err) => console.log(err));
  };

  return { fetchData, fetchCmsPage };
};

export default useCmsPage;
