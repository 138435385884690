export const API_ENDPOINTS = {
  LOGIN: "/login",
  REGISTER: "/register",
  LOGOUT: "/logout",
  FORGET_PASSWORD: "/forget-password",
  CATEGORIES: "/categories/topcategories",
  DIETARY: "/dietary.json",
  BRANDS: "/brands.json",
  PRODUCTS: "/apis/products.json",
  PRODUCT: "/product.json",
  RELATED_PRODUCTS: "/related_products.json",
  BEST_SELLER_PRODUCTS: "/products/bestselling",
  TRENDING_PRODUCTS: "/products/trending",
  BEST_SELLER_GROCERY_PRODUCTS: "/products_best_seller_grocery.json",
  POPULAR_PRODUCTS: "/products_popular.json",
  COOKIES_PRODUCTS: "/products_cookies.json",
  CHIPS_PRODUCTS: "/products_chips.json",
  POPCORN_JERKY_PRODUCTS: "/products_popcorn_jerky.json",
  FRESH_VEGETABLES_PRODUCTS: "/products_fresh_vegetables.json",
  SEARCH: "/search.json",
  ORDERS: "/orders.json",
  ORDER: "/order.json",
  ORDER_STATUS: "/order-status.json",
  ADDRESS: "/address.json",
  PAYMENT: "/payment.json",
  CONTACT: "/contact.json",
  SHOP: "/shop.json",
  SHOPS: "/shops.json",
  WISHLIST: "/wishlist/save",
  ALL_CATEGORIE: "/apis/all-single-categories.json",
  SLIDER: "apis/banners.json",
  FETCH_COMPANY_DETAILS_FORM_DOMAIN_NAME: "/company",
  GET_BANNERS: "/banner/fetchall",
  VALID_CART: "/orders/validateCart",
  PLACE_ORDER: "/orders",
  SEND_OTP: "/user/otp/send",
  VERIFY_OTP: "/user/otp/verify",
  SIGN_UP: "/users/signup",
  IS_EXISTING_USER: "/users",
  EDIT_CUSTOMER_PROFILE: "/user/profile/edit",
  FETCH_CATEGORIES_LIST: "/categories",
  FETCH_PRODUCT_LIST: "/products",
  FETCH_BRAND_LIST: "/brands",
  FETCH_WISHLIST: "/wishlist/list",
  FETCH_ADRESS_LIST: "/user/address",
  FETCH_COUNTRY_LIST: "/locality/countries",
  ADD_ADRESS: "/user/address",
  FETCH_STATE_LIST: "/locality/state",
  FETCH_CITY_LIST: "/locality/city",
  FETCH_PRODUCT_DETAIS_BY_ID: "/products",
  GET_SALES_LIST: "/sales/customers",
  GET_SALES_DETAILS: "/sales",
  CANCEL_ORDER: "/orders",
  DELETE_ADDRESS: "/user/address",
  FETCH_ORDER_TRACKING_TRAIL: "/ecommerce/merchant/order/trackingtrail",
  FETCH_BRAND_LIST_CATEGORY: "/brandsbycategory",
  DELETE_WISHLIST: "/wishlist/delete",
  AVAILABLE_QUANTITY: "/product/availableQty",
  PAYTM_STATUS: "/paytm/order/status",
  REORDER: "/reorder",
  WISHLIST_STOCK: "/wishlist/list/stock",
  CART_LIST: "/cart/list",
  UPDATE_SETTINGS: "/api/v1/ecommerceadmin/updateecomeercesettings/fetchall",

  SET_ADVERTISEMENT: "/advertisement/fetchall",
  GET_FAV_LOADER: "/utilitydrive/favicon/fetchall",
};
