import { ActionTypes } from "../constants/ActionTypes";
import http from "../../utils/http";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  ReactProjectBaseUrl,
  FETCH_CATEGORIES_LIST_LIMIT,
} from "../../utils/Constants";
import { getApiToken } from "../../utils/get-token";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";

export const getCategories = () => async (dispatch, getState) => {
  const token = getApiToken()
  const categories = getState().categories;
  const categoriesPageCount = await getState().categoriesPageCount;

  let newCategoriesArray = [];

  const response = await axiosFunction(token,`${ReactProjectBaseUrl + API_ENDPOINTS.FETCH_CATEGORIES_LIST}?page=0&length=1000`,"GET")
  const categoryResponse = response.data;
  let categoryList = [];
  let allCategoriesCount = 0;

  if (categoryResponse) {
    if (categoryResponse.status) {
      categoryList = categoryResponse.response.categoryDTOs;
      allCategoriesCount = categoryResponse.response.count;
    }
  }

  const appendedCategoriesList = newCategoriesArray.concat(categoryList);

  dispatch({
    type: ActionTypes.SET_CATEGORY_TOTAL_COUNT,
    payload: allCategoriesCount,
  });

  dispatch({
    type: ActionTypes.SET_CATEGORY_COUNTER,
    payload: categoriesPageCount + 1,
  });

  dispatch({
    type: ActionTypes.SET_CATEGORIES,
    payload: appendedCategoriesList,
  });
};
