import { ActionTypes } from "../constants/ActionTypes";
import http from "../../utils/http";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  ReactProjectBaseUrl,
  FETCH_BRAND_LIST_LIMIT,
} from "../../utils/Constants";
import { getToken, getApiToken } from "../../utils/get-token";
import axios from "axios";
import { useState } from "react";

export const getbrandaction = () => async (dispatch, getState) => {
  const token = getApiToken()
  const categories = getState().brands;
  const brandPageCount = await getState().brandPageCount;
  let response = [];
  let newCategoriesArray = [...categories];
  http.interceptors.request.use(
    (config) => {
      config.headers['API_TOKEN'] =  token
      config.params = {
        ...config.params,
        page: getState().brandPageCount,
        length: FETCH_BRAND_LIST_LIMIT,
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const API_TOKEN = getApiToken();
  await axios
    .get(
      ReactProjectBaseUrl +
        API_ENDPOINTS.FETCH_BRAND_LIST +
        "?page=" +
        getState().brandPageCount +
        "&length=" +
        FETCH_BRAND_LIST_LIMIT,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          API_TOKEN: `${API_TOKEN ? API_TOKEN : ""}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      }
    )
    .then((data) => {
      response = data.data;
    });

  const brandResponse = response.response;
  let brandList = [];
  let allBrandsCount = 0;

  if (brandResponse) {
    brandList = brandResponse.ecommerceResponse;
    allBrandsCount = brandResponse.count;
  }

  const appendedCategoriesList = newCategoriesArray.concat(brandList);

  dispatch({
    type: ActionTypes.SET_BRAND_TOTAL_COUNT,
    payload: allBrandsCount,
  });
  dispatch({
    type: ActionTypes.SET_BRAND_TOTAL_COUNT,
    payload: allBrandsCount,
  });

  dispatch({
    type: ActionTypes.SET_BRAND_COUNTER,
    payload: brandPageCount + 1,
  });

  dispatch({ type: ActionTypes.SET_BRAND, payload: appendedCategoriesList });
};

export const getBrandsByCategoryAction = () => async (dispatch, getState) => {
  const token = getApiToken()
  let response = [];
  let newCategoriesArray = [];

  let categoryFilerIds = [];
  const categoryFilter = getState().categoryFilter;
  for (let index = 0; index < categoryFilter.length; index++) {
    categoryFilerIds.push(categoryFilter[index].id);
  }
  const data = "";
  await axios
    .post(
      ReactProjectBaseUrl +
        API_ENDPOINTS.FETCH_BRAND_LIST_CATEGORY +
        "?categorys=" +
        categoryFilerIds.toString(),
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          API_TOKEN: token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      }
    )
    .then((data) => {
      response = data.data;
    });

  const brandResponse = response.response;
  let brandList = [];
  let allBrandsCount = 0;

  if (brandResponse) {
    brandList = brandResponse;
    allBrandsCount = brandResponse.length;
  }

  dispatch({
    type: ActionTypes.SET_BRAND_TOTAL_COUNT,
    payload: allBrandsCount,
  });
  dispatch({ type: ActionTypes.SET_BRAND, payload: brandList });
};
