import { ActionTypes } from '../constants/ActionTypes';
import { API_ENDPOINTS } from '../../utils/api-endpoints';
import {  getApiToken } from '../../utils/get-token';
import { ReactProjectBaseUrl } from '../../utils/Constants'
import { axiosFunction } from '../../utils/globalFunctions/axiosFunction';

export const getBanners = () =>  async ( dispatch ) => {
  const token = getApiToken()

  const response = await axiosFunction(token,`${ReactProjectBaseUrl}${API_ENDPOINTS.GET_BANNERS}`,"GET")
  const bannerResposne = response.data;
  let bannerList = [];

  if (bannerResposne) {
    if (bannerResposne.status) {
      bannerList = bannerResposne.response
    }
  }
  
  dispatch({ type : ActionTypes.SET_BANNERS , payload : bannerList});
};