import { axiosFunction } from "../utils/globalFunctions/axiosFunction";
import { ReactProjectBaseUrl } from "../utils/Constants";
import { getApiToken, getBranchToken, isGeofenchingEnabled } from "../utils/get-token";

const useCustomerActiveOrNot = () => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken()
  const customerActiveOrNotFunc = async (contactId) => {
    const res = await axiosFunction(
      token,
      ReactProjectBaseUrl + `/users/checkactivedeactive?contactId=${contactId}`,
      "GET"
    );

    return res.data.response.isActive;
  };

  return { customerActiveOrNotFunc };
};

export default useCustomerActiveOrNot;
