import React from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { locationSvg } from "../../utils/Constants";

const NavbarLeftSection = ({
  setShowGeoLoacationModalComponent,
  ShowSideMenuOnMobileViewEventHandler,
}) => {
  const { companyDetails, logo } = useSelector((state) => state);
  const isMobile = useResponsive();
  return (
    <div className="d-flex align-items-center  leftNavItems col-3 w-auto mw-25">
      <p>{companyDetails.logo}</p>
      {isMobile && (
        <a
          style={{ marginRight: "10px" }}
          className="mobilesidemenu"
          onClick={() => ShowSideMenuOnMobileViewEventHandler()}
        >
          <i className="material-icons">menu</i>
          {/* <span className="icon-name">Menu</span> */}
        </a>
      )}
      <div className="logo d-flex align-items-center">
        <Link to="/">
          <img
            src={logo ? logo : "/images/VasyERP.png"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/VasyERP.png";
            }}
            alt="Company Logo"
            loading="lazy"
            style={{
              height: "100%",
              // width: "70px",   // we cant set static same height and width because logo can be a rectangle
              // height: "70px",
              // minHeight: "70px",
              // minWidth: "70px",
              maxHeight: "70px",
              maxWidth: "135px",
            }}
          />
        </Link>
      </div>
      {isMobile && (
        <div
          className="locationMenu menuMobile col-sm-9 d-flex align-items-center"
          onClick={() => setShowGeoLoacationModalComponent(false)}
        >
          {/* <div className="col-sm-4 px-2 d-flex locationIcon">
            <i className="material-icons">pin_drop</i>
          </div> */}
          <div
            className="col-sm-4 px-2 locationIcon"
            dangerouslySetInnerHTML={{ __html: locationSvg }}
          ></div>
          <div className="detail locationDetail col-sm-8 px-0">
            <h6 className="m-0 pe-sm-2">Location</h6>
            <span id="location">
              {Cookies.get("label") ? Cookies.get("label") : "Select Location"}
            </span>
          </div>
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
            style={{
              right: "8px",
              position: "absolute",
              zIndex: "9999",
              color: "var(--primary-color)",
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

export default NavbarLeftSection;
