import styled from "styled-components";

export const NavbarMobileContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 53px;
  z-index: 9999;

  .navbar-list {
    display: flex;
    list-style-type: none;
    background-color: white;
    width: 100%;
    height: 54px;
    justify-content: space-between;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    padding-top: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    z-index: 9999;
    .navmenu.active {
      a .material-icons,
      a .icon-name {
        color: black;
      }
    }
    #cart_menu_container {
      position: relative;
      .cart_item_count {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        box-shadow: 0 54px 55px rgba(0, 0, 0, 0.25),
          0 -12px 30px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.12),
          0 12px 13px rgba(0, 0, 0, 0.17), 0 -3px 5px rgba(0, 0, 0, 0.09);
        color: var(--secondary-color);
        height: 18px;
        right: -8px;
        padding: 2px;
        top: -3px;
        width: 18px;

        left: 20px;
        background: white;
        h3 {
          font-size: 10px !important;
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }
  .material-icons {
    font-size: 26px;
    justify-content: center;
    color: #898989;
  }
  .icon-name {
    font-size: 16px;
    color: #898989;
  }

  @media screen and (max-width: 568px) {
    #cd-shadow-layer.is-visible {
      display: block;
      animation: ease-in-out 0.3s;
    }
    #cd-shadow-layer {
      position: fixed;
      min-height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0.5;
      cursor: pointer;
      z-index: 111;
      display: none;
      margin: 0;
    }
    .is-visible {
      overflow: hidden;
    }
    .navbar-list #hamburger_container {
      position: absolute;
    }
    .mobilesidemenubar,
    .mobilesidemenubar .menuLinks.active {
      width: 100%;
    }
    .mobilesidemenubar .menuLinks {
      align-items: flex-start;
      background-color: #fff;
      background-color: var(--product-bg-color);
      bottom: 60px;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      height: calc(100% - 60px);
      justify-content: flex-start;
      left: 0;
      padding: 0 2rem;
      position: fixed;
      top: 0;
      -webkit-transform: translate(-100%);
      transform: translate(-100%);
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
      width: 300px;
      z-index: 1111111;
    }
    .mobilesidemenubar .menuLinks.active {
      align-items: flex-start;
      background-color: #fff;
      background-color: var(--product-bg-color);
      bottom: 60px;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      height: calc(100% - 50px);
      justify-content: flex-start;
      left: 0;
      overflow: auto;
      padding: 0 2rem;
      position: fixed;
      top: 0;
      -webkit-transform: translate(0);
      transform: translate(0);
      transition: transform 0.3s, -webkit-transform 0.3s;
      z-index: 1111111;
    }
    .menuLinks li a {
      align-items: center;
      border-radius: 5px;
      color: #000;
      display: flex;
      font-family: sans-serif;
      font-size: 12.5px !important;
      letter-spacing: 1px;
      line-height: 1;
      padding: 1rem 1.25em;
      position: relative;
      text-decoration: none;
      text-transform: capitalize;
      transition: 0.3s;
      vertical-align: middle;
    }
    .menuLinks li {
      list-style: none;
    }
    .mobileNavbarSearch {
      bottom: 10px;
      display: none;
      opacity: 0;
      position: fixed;
      right: 13%;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      width: 4%;
      z-index: 100;
    }
  }
`;
