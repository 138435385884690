import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useGeolocated } from "react-geolocated";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { setGeoLocationFlag } from "../../State/actions/DefaultUtilsActions";
import { GOOGLE_API_KEY, ReactProjectBaseUrl } from "../../utils/Constants";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import {
  findCoords,
  findServiciblityareaFnc,
} from "../../utils/globalFunctions/findNearestBranch";
import { getTopCategory } from "../../State/actions/TopCategoriesAction";
import {
  fetchBestSellingProducts,
  fetchTrendingProducts,
} from "../../State/actions/productActions";
import { getCategories } from "../../State/actions/CategoryActions";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { ClearCart } from "../../State/actions/addToCartActions";
import { getApiToken } from "../../utils/get-token";
let temporaryBranchList = [];
const GeoLocationModalComponent = (props) => {
  const dispatch = useDispatch();

  const companyDetails = useSelector((state) => state.companyDetails);

  const [value, setValue] = useState(null);
  const [isUserServiceable, setIsUserServiceable] = useState(true);
  const [loading, setLoading] = useState(false);

  // Async function to update data
  async function updateData(branchlistdata) {
    let data = [...branchlistdata];
    data = data.filter(
      (branch) =>
        branch.area_serviciblity_id !== 0 &&
        branch.is_branch_serviciblity === 0 &&
        branch.is_active === 0
    );
    try {
      for (let i = 0; i < data.length; i++) {
        try {
          if(data[i].branch_lat.length === 0){
            let branchCoords = await findCoords(data[i].address);
            // Update the branch in the temporary array
            data[i] = {
              ...data[i],
              branch_lat: branchCoords.lat,
              branch_lng: branchCoords.lng,
            };
            
          }else {
            data[i] = {
              ...data[i],
              branch_lat : Number(data[i].branch_lat),
              branch_lng : Number(data[i].branch_lng),
            }
          }
        } catch (error) {
          console.error(error);
          // Handle the error as needed, e.g., set default values or skip the update
        }
      }

      temporaryBranchList = [...data];
    } catch (error) {
      console.error(error);
    }
  }

  // Call the async function
  async function processData(branchlistdata) {
    try {
      await updateData(branchlistdata);
      // Now, the data array has been updated with coordinates
    } catch (error) {
      console.error("Error processing data:", error);
    }
  }

  const saveUserLocationDropdown = (response) => {
    try {
      let pincode;
      let country =
        response.results[0].address_components[
          response.results[0].address_components.length - 2
        ].long_name;
      let state =
        response.results[0].address_components[
          response.results[0].address_components.length - 3
        ].long_name;
      let city =
        response.results[0].address_components[
          response.results[0].address_components.length - 4
        ].long_name;

      for (let index = 0; index < response.results.length; index++) {
        let isPincodeFound = false;
        let addressVo = response.results[index].address_components;

        for (let index2 = 0; index2 < addressVo.length; index2++) {
          const element = addressVo[index2].types[0];
          if (element === "postal_code") {
            pincode =
              addressVo[index2].long_name || addressVo[index2].short_name;
            isPincodeFound = true;
            break;
          }
        }

        if (isPincodeFound) {
          break;
        }
      }
      // return axiosFunction(
      //   `https://accounting.vasyerp.in/api/v1/ecommerceadmin/servicebility/branchlist?companyId=64`,"GET"
      // );
      return { country, state, city, pincode };
    } catch (error) {
      throw new Error(error);
    }
  };

  const saveUserLocation = ({ response }) => {
    let pincode = "";
    let shortName = "";
    let longName = "";

    const address = response.results[0].formatted_address;
    try {
      var country =
        response.results[0].address_components[
          response.results[0].address_components.length - 2
        ].long_name;
      var state =
        response.results[0].address_components[
          response.results[0].address_components.length - 3
        ].long_name;
      var city =
        response.results[0].address_components[
          response.results[0].address_components.length - 4
        ].long_name;
    } catch (error) {
      throw new Error(error);
    }

    for (let index = 0; index < response.results.length; index++) {
      let isPincodeFound = false;
      let addressVo = response.results[index].address_components;

      for (let index2 = 0; index2 < addressVo.length; index2++) {
        const element = addressVo[index2].types[0];
        if (element === "postal_code") {
          longName = addressVo[index2].long_name;
          shortName = addressVo[index2].short_name;
          isPincodeFound = true;
          break;
        }
      }

      if (isPincodeFound) {
        break;
      }
    }
    return { country, state, city, address, shortName, longName };
  };
  const setMap = (value) => {
    let latitude;
    let longitude;
    let findNearestBranch;
    geocodeByPlaceId(value.value.place_id)
      .then((results) => {
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
        setValue(value);
        setLoading(true);
        setIsUserServiceable(true);
        Geocode.setApiKey(GOOGLE_API_KEY);
        Geocode.fromLatLng(latitude.toString(),longitude.toString())
          .then((response) => {
            // return axiosFunction(
            //   "https://accounting.vasyerp.in/api/v1/ecommerceadmin/servicebility/branchlist?companyId=64","GET"
            // );
            return { response };
          })
          .then(async (res) => {
            const { response } = res;
            const { country, state, city, address, pincode } =
              saveUserLocationDropdown(response);
            if (companyDetails.response.isGeofenchingEnabled === 1) {
              findNearestBranch = await findServiciblityareaFnc(
                temporaryBranchList,
                latitude,
                longitude,
                pincode
              );
            } else {
              findNearestBranch = {};
            }
            if (
              findNearestBranch !== null &&
              Object.keys(findNearestBranch).length > 0 &&
              companyDetails.response.isGeofenchingEnabled === 1
            ) {
              //found nearest branch and has serv. enabled
              console.log(findNearestBranch, "NearestBranch");
              Cookies.set("BRANCH_TOKEN", findNearestBranch.api_token);
              Cookies.set("COUNTRY", country);
              Cookies.set("STATE", state);
              Cookies.set("CITY", city);
              Cookies.set("label", value.label);
              Cookies.set("pincode", pincode);
              setIsUserServiceable(true);
              props.setShowGeoLoacationModalComponent(true);
              saveUserLocation({ response });
              dispatch(getCategories());
              dispatch(getTopCategory());
              dispatch(fetchBestSellingProducts());
              dispatch(fetchTrendingProducts());
              dispatch(setGeoLocationFlag(true));
              //empty the cart when user change location
              dispatch(ClearCart());
              setLoading(false);
            } else if (
              findNearestBranch !== null &&
              Object.keys(findNearestBranch).length === 0 &&
              companyDetails.response.isGeofenchingEnabled === 1
            ) {
              //no nearest branch found but has serv. enabled
              setIsUserServiceable(false);
              setLoading(false);
            } else if (
              findNearestBranch === null &&
              companyDetails.response.isGeofenchingEnabled === 1
            ) {
              //serviceability enable but end user is not in range
              // Cookies.remove("BRANCH_TOKEN");
              // Cookies.remove("API_TOKEN");
              setIsUserServiceable(false);
              setLoading(false);
            } else {
              //serv. disabled normal flow continues
              Cookies.set("API_TOKEN", companyDetails.response.token);
              Cookies.set("COUNTRY", country);
              Cookies.set("STATE", state);
              Cookies.set("CITY", city);
              Cookies.set("label", value.label);
              Cookies.set("pincode", pincode);
              setIsUserServiceable(true);
              props.setShowGeoLoacationModalComponent(true);
              saveUserLocation({ response });
              dispatch(getCategories());
              dispatch(getTopCategory());
              dispatch(fetchBestSellingProducts());
              dispatch(fetchTrendingProducts());
              dispatch(setGeoLocationFlag(true));
              setLoading(false);
            }
          })
          .catch((error) => {
            // Handle errors from any step in the chain
            console.error("Error:", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        return;
      });
  };
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const getCurrentLocation = () => {
    const token = getApiToken();
    if (coords) {
      setLoading(true);
      setIsUserServiceable(true);
      Cookies.set("latitude", coords.latitude);
      Cookies.set("longitude", coords.longitude);
      Geocode.setApiKey(GOOGLE_API_KEY);
      let findNearestBranch;
      Geocode.fromLatLng(coords.latitude, coords.longitude)
        .then((response) => {
          return Promise.all([
            response,
            axiosFunction(
              token,
              `${ReactProjectBaseUrl}/api/v1/geofencing/areaservicibility/serviciblityarea`,
              "GET"
            ),
          ]);
        })
        .then(async ([response, branchlistdata]) => {
          // Assuming 'shortName' is accessible from the previous step
          const { country, state, city, address, shortName, longName } =
            saveUserLocation({ response });
          if (companyDetails.response.isGeofenchingEnabled === 1) {
            findNearestBranch = await findServiciblityareaFnc(
              temporaryBranchList,
              coords.latitude,
              coords.longitude,
              shortName
            );
          } else {
            findNearestBranch = {};
          }
          if (
            findNearestBranch !== null &&
            Object.keys(findNearestBranch).length > 0 &&
            companyDetails.response.isGeofenchingEnabled === 1
          ) {
            //found nearest branch and has serv. enabled
            console.log(findNearestBranch, "NearestBranch");
            Cookies.set("BRANCH_TOKEN", findNearestBranch.api_token);
            Cookies.set("COUNTRY", country);
            Cookies.set("STATE", state);
            Cookies.set("CITY", city);
            Cookies.set("label", address);
            Cookies.set("pincode", shortName);
            setIsUserServiceable(true);
            props.setShowGeoLoacationModalComponent(true);
            saveUserLocation({ response });
            dispatch(getCategories());
            dispatch(getTopCategory());
            dispatch(fetchBestSellingProducts());
            dispatch(fetchTrendingProducts());
            dispatch(setGeoLocationFlag(true));
            //empty the cart when user change location
            dispatch(ClearCart());
            setLoading(false);
          } else if (
            findNearestBranch !== null &&
            Object.keys(findNearestBranch).length === 0 &&
            companyDetails.response.isGeofenchingEnabled === 1
          ) {
            //no nearest branch found but has serv. enabled
            setIsUserServiceable(false);
            setLoading(false);
          } else if (
            findNearestBranch === null &&
            companyDetails.response.isGeofenchingEnabled === 1
          ) {
            //serviceability enable but end user is not in range
            // Cookies.remove("BRANCH_TOKEN");
            // Cookies.remove("API_TOKEN");
            setIsUserServiceable(false);
            setLoading(false);
          } else {
            //serv. disabled normal flow continues
            Cookies.set("API_TOKEN", companyDetails.response.token);
            Cookies.set("COUNTRY", country);
            Cookies.set("STATE", state);
            Cookies.set("CITY", city);
            Cookies.set("label", address);
            Cookies.set("pincode", shortName);
            setIsUserServiceable(true);
            props.setShowGeoLoacationModalComponent(true);
            saveUserLocation({ response });
            dispatch(getCategories());
            dispatch(getTopCategory());
            dispatch(fetchBestSellingProducts());
            dispatch(fetchTrendingProducts());
            dispatch(setGeoLocationFlag(true));
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle errors from any step in the chain
          console.error("Error:", error);
          setLoading(false);
        });
    } else {
      globalSwalFunction(
        "Please give location permission to your browser!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
    }
  };

  const [countryArray, setCountryArray] = useState("in");
  // countryArray.push('in')
  useEffect(() => {
    let blankArray = [];
    let countryArraynew = blankArray;

    if (companyDetails.status) {
      try {
        blankArray =
          companyDetails.response.allowedGoogleLocationPlaces.split(",");
      } catch (error) {
        blankArray.push("in");
      }
      countryArraynew = [];
      blankArray.forEach((countryelement) => {
        if (countryelement) {
          countryArraynew.push(countryelement);
        }
      });

      setCountryArray(countryArraynew);
    } else {
      countryArraynew.push("in");
      setCountryArray(countryArraynew);
    }
  }, []);

  useEffect(() => {
    if (companyDetails?.response?.token) {
      axiosFunction(
        companyDetails.response.token,
        `${ReactProjectBaseUrl}/api/v1/geofencing/areaservicibility/serviciblityarea`,
        "GET"
      ).then((res) => {
        processData(res.data.response);
      });
    }
  }, [companyDetails]);
  useEffect(() => {
    document.querySelector("body").className = "location_modal";
    return () => {
      document.querySelector("body").classList.remove("location_modal");
    };
  });

  return (
    <>
      <div
        className="container accountCredPopup justify-content-center align-items-center d-flex"
        id="accountCredPopup"
      >
        <div className="form-container sign-in-container mobileNumberRequest">
          <div>
            <h1 className="fs-5 fw-bolder lh-base welcomeBack">
              Add Your Location
            </h1>
          </div>
          <div>
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_API_KEY}
              selectProps={{
                value,
                onChange: setMap,
              }}
              placeholder="Search for Location"
              autocompletionRequest={{
                componentRestrictions: {
                  country: countryArray,
                },
              }}
              onLoadFailed={(error) =>
                console.error("Could not inject Google script", error)
              }
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="search-box">
                  <i className="material-icons search-icon">arrow_back</i>
                </div>
              )}
            </GooglePlacesAutocomplete>
          </div>
          <div className="location_img_container">
            <div
              onClick={() => getCurrentLocation()}
              className="current_loc_container"
            >
              <img className="gps_icon" src="/images/GPS.svg" />
              <h1 className="current_loc_title">Use Current Location</h1>
            </div>
            <div className="default_location_picture">
              {!loading && (
                <>
                  {isUserServiceable ? (
                    <img src="/images/Location.png" />
                  ) : (
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src="/images/not-serviceable.webp"
                    />
                  )}
                </>
              )}
              {loading && (
                <img
                  style={{ height: "150px", width: "150px" }}
                  src={"/images/searching-loading.gif"}
                  alt="preloader"
                />
              )}
            </div>
            {!isUserServiceable &&
              companyDetails.response.isGeofenchingEnabled === 1 && (
                <div>
                  <h1 className="text-center font-weight-600 mt-2">
                    Location is not serviceable
                  </h1>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeoLocationModalComponent;
