import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { ReactProjectBaseUrl } from "../../utils/Constants";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import OtpDialogueModal from "./childComponents/OtpDialogueModal";
import LoginDialogueModal from "./childComponents/LoginDialogueModal";
import SignUpDialogueModal from "./childComponents/SignUpDialogueModal";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { getApiToken } from "../../utils/get-token";

const LoginOtpModalComponent = ({
  ShowLoginOtpModalComponent,
  setShowLoginOtpModalComponent,
  setshowHideLoginForm,
}) => {
  const counterSeconds = 60;
  const [ShowMobileNoComponent, setShowMobileNoComponent] = useState(true);
  const [ShowOtpVarificationComponent, setShowOtpVarificationComponent] =
    useState(false);
  const [ShowSignUpComponent, setShowSignUpComponent] = useState(false);
  const [MobileNo, setMobileNo] = useState("");
  const [OTP, setOTP] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResendOtp, setIsLoadingResendOtp] = useState(false);
  const [ShowResendBtn, setShowResendBtn] = useState(false);
  const [counter, setCounter] = React.useState(counterSeconds);
  const [isSignupDisabled, setIsSignupDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const requestData = {};

  const [sendOtpMessage, setSendOtpMessage] = useState("");
  const [verifyOtpMessage, setVerifyOtpMessage] = useState("");

  const data = useSelector((state) => state.companyDetails);

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      sendOtp();
    }
  };

  const validateMobileNumber = (mobileNumber) => {
    const firstCharacter = mobileNumber.charAt(0);
    if (mobileNumber.length === 0) {
      setError(true);
      setErrorMessage("Please enter a valid number");
      return false;
    } else if (mobileNumber.length !== 10) {
      setError(true);
      setErrorMessage("Invalid Mobile Number!");
      return false;
    } else if (isNaN(mobileNumber)) {
      setError(true);
      setErrorMessage("Invalid Mobile Number!");
      return false;
    } else if (firstCharacter < "6" || firstCharacter > "9") {
      setError(true);
      setErrorMessage("Invalid Mobile Number!");
      return false;
    } else {
      setError(false);
      return true;
      // sendOtp("newOtp")
    }
  };

  const sendOtp = async (type) => {
    console.log("type", type);
    if (type === "resend") {
      setIsLoadingResendOtp(true);
      setOTP(0);
      setTimeout(() => {
        document.querySelector(".otpInputItem:first-child input").focus();
      }, 500);
      setSendOtpMessage("");
    } else {
      setIsLoading(true);
    }

    if (validateMobileNumber(MobileNo)) {
      const token = getApiToken();
      try {
        const res = await axiosFunction(
          token,
          `${ReactProjectBaseUrl}${API_ENDPOINTS.SEND_OTP}/${MobileNo}`,
          "POST",
          requestData
        );
        const sendOtpResponse = res.data;
        // console.log("sendOtpResponse", sendOtpResponse);
        // return;
        // return;
        if (sendOtpResponse?.status) {
          setIsLoading(false);
          setIsLoadingResendOtp(false);
          setShowMobileNoComponent(false);
          setShowOtpVarificationComponent(true);
          setShowSignUpComponent(false);
          setTimeout(() => {
            document.querySelector(".otpInputItem:first-child input").focus();
          }, 500);
          setSendOtpMessage("");
          setVerifyOtpMessage(sendOtpResponse?.response);
        } else {
          setIsLoading(false);
          setIsLoadingResendOtp(false);
          if (sendOtpResponse?.response?.count === "0") {
            if (type === "resend") {
              setSendOtpMessage(sendOtpResponse?.response?.message);

              showLoginOtpModalEventHandler("close");
              globalSwalFunction(
                `${sendOtpResponse?.response?.message}`,
                '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
              );
            } else {
              setSendOtpMessage(sendOtpResponse?.response?.message);
            }
            setSendOtpMessage(sendOtpResponse?.response?.message);
          } else {
            setSendOtpMessage("");
          }
        }
      } catch (error) {
        setIsLoading(false);
        setIsLoadingResendOtp(false);
        globalSwalFunction(
          "Something Went Wrong...!!!",
          '<i class="fas fa-times-circle text-danger fs-5"></i>'
        );
      }
    } else {
      setIsLoading(false);
      setIsLoadingResendOtp(false);
    }

    setCounter(counterSeconds);
    setShowResendBtn(false);
  };

  // console.log("sendOtpMessage", sendOtpMessage);

  const showLoginOtpModalEventHandler = (type) => {
    setIsLoading(false);
    if (type === "open") {
      setShowLoginOtpModalComponent(true);
      setshowHideLoginForm(true);
      document.getElementById("mobileNoInput").focus();
    } else {
      setShowLoginOtpModalComponent(false);
      setshowHideLoginForm(false);
    }
  };

  useEffect(() => {
    initializeMobileNoComponent();
  }, []);

  const initializeMobileNoComponent = () => {
    setIsLoading(false);
    setShowMobileNoComponent(true);
    setShowOtpVarificationComponent(false);
    setShowSignUpComponent(false);
    const mobileNoInput = document.getElementById("mobileNoInput");
    if (mobileNoInput) {
      mobileNoInput.focus();
    }
  };

  useEffect(() => {
    if (ShowOtpVarificationComponent) {
      const timer =
        counter > 0 &&
        setInterval(() => {
          if (counter === 1) {
            setShowResendBtn(true);
          }
          setCounter(counter - 1);
        }, 1000);
      return () => clearInterval(timer);
    }
  }, [counter, ShowOtpVarificationComponent]);

  function checkAnyCMSPagesFoundOrNotForOTPModal() {
    let cmsPageFlagList = [];

    if (data?.status) {
      cmsPageFlagList = data?.response?.cmspageflag;
    }
    let sum = 0;
    let termsAndConditions = 0;
    let privacyPolicy = 0;
    let returnPolicy = 0;
    for (let index = 0; index < cmsPageFlagList.length; index++) {
      if (cmsPageFlagList[index].AboutUs) {
        sum += cmsPageFlagList[index].AboutUs;
      }
      if (cmsPageFlagList[index].ContactUs) {
        sum += cmsPageFlagList[index].ContactUs;
      }
      if (cmsPageFlagList[index].privacyPolicy) {
        sum += cmsPageFlagList[index].privacyPolicy;
        privacyPolicy += cmsPageFlagList[index].privacyPolicy;
      }
      if (cmsPageFlagList[index].TermsAndCondition) {
        sum += cmsPageFlagList[index].TermsAndCondition;
        termsAndConditions += cmsPageFlagList[index].TermsAndCondition;
      }
      if (cmsPageFlagList[index].ReturnPolicy) {
        sum += cmsPageFlagList[index].ReturnPolicy;
        returnPolicy += cmsPageFlagList[index].ReturnPolicy;
      }
    }
    return {
      termsAndConditions: termsAndConditions,
      privacyPolicy: privacyPolicy,
      returnPolicy: returnPolicy,
    };
  }

  return (
    <>
      {/* <!-- account sign in/up popup --> */}
      {ShowLoginOtpModalComponent && (
        <div
          id="accountCredPopup-shadow-layer"
          style={{
            display: "block",
          }}
          // onClick={() => showLoginOtpModalEventHandler("close")}
        ></div>
      )}
      <div
        className="container accountCredPopup justify-content-center align-items-center"
        id="accountCredPopup"
        style={{
          display: ShowLoginOtpModalComponent ? "flex" : "none",
        }}
      >
        {ShowSignUpComponent && (
          <SignUpDialogueModal
            MobileNo={MobileNo}
            setMobileNo={setMobileNo}
            isSignupDisabled={isSignupDisabled}
            setIsSignupDisabled={setIsSignupDisabled}
            setShowMobileNoComponent={setShowMobileNoComponent}
            setShowOtpVarificationComponent={setShowOtpVarificationComponent}
            showLoginOtpModalEventHandler={showLoginOtpModalEventHandler}
          />
        )}
        {ShowMobileNoComponent && (
          <LoginDialogueModal
            isLoading={isLoading}
            MobileNo={MobileNo}
            setMobileNo={setMobileNo}
            sendOtp={sendOtp}
            showLoginOtpModalEventHandler={showLoginOtpModalEventHandler}
            handleKeyPress={handleKeyPress}
            error={error}
            errorMessage={errorMessage}
            checkAnyCMSPagesFoundOrNotForOTPModal={
              checkAnyCMSPagesFoundOrNotForOTPModal
            }
            sendOtpMessage={sendOtpMessage}
          />
        )}
        {ShowOtpVarificationComponent && (
          <OtpDialogueModal
            setShowOtpVarificationComponent={setShowOtpVarificationComponent}
            counter={counter}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            MobileNo={MobileNo}
            setMobileNo={setMobileNo}
            OTP={OTP}
            setOTP={setOTP}
            ShowResendBtn={ShowResendBtn}
            requestData={requestData}
            setShowMobileNoComponent={setShowMobileNoComponent}
            setShowSignUpComponent={setShowSignUpComponent}
            sendOtp={sendOtp}
            isLoadingResendOtp={isLoadingResendOtp}
            showLoginOtpModalEventHandler={showLoginOtpModalEventHandler}
            checkAnyCMSPagesFoundOrNotForOTPModal={
              checkAnyCMSPagesFoundOrNotForOTPModal
            }
            setVerifyOtpMessage={setVerifyOtpMessage}
            verifyOtpMessage={verifyOtpMessage}
            setSendOtpMessage={setSendOtpMessage}
            sendOtpMessage={sendOtpMessage}
          />
        )}
      </div>
    </>
  );
};

export default LoginOtpModalComponent;
