import  {ActionTypes}  from "../constants/ActionTypes";
const intialState = [];

export const relatedProductsReducer = (state = intialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.SET_RETALED_PRODUCTS:
        return payload;
      default:
        return state;
    }
  };
  

