import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


import reducers from "./reducers/index";
import thunk from "redux-thunk";

const persistConfig = {
    key: 'persist-root',
    storage,
  }

const persistedReducer =  persistReducer(persistConfig,reducers)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store)
export default store
export {persistor}

