import  {ActionTypes}  from "../constants/ActionTypes";
const intialState = [];

export const bannerReducers = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BANNERS:
      return payload;
    default:
      return state;
  }
};