import React, { memo } from "react";
const PrimaryButton = ({
  id,
  className,
  name,
  style,
  type,
  onClick,
  disabled,
  hidden,
  dataToggle,
  dataTarget,
  datatext,
  areaLabel,
  ariaLabelledBy,
  tabIndex,
  icon,
  title,
  dataBsDismiss,
  dataBsTarget,
  faIcon = "",
  handleKeyPress,
}) => {
  return (
    <>
      <button
        id={id}
        className={className}
        title={title}
        type={type}
        style={style}
        onClick={onClick}
        disabled={disabled}
        hidden={hidden}
        data-toggle={dataToggle}
        data-target={dataTarget}
        data-text={datatext}
        tabIndex={tabIndex}
        aria-label={areaLabel || name}
        aria-labelledby={ariaLabelledBy}
        data-bs-dismiss={dataBsDismiss}
        data-bs-target={dataBsTarget}
        handleKeyPress={handleKeyPress}
      >
        {icon}
        {name}
        &ensp;
        {faIcon}
      </button>
    </>
  );
};

export default memo(PrimaryButton);
