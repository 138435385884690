import React from "react";
import InputComponent from "../../commonComponents/FormInputs/InputComponent";
import Cookies from "js-cookie";
import { ReactProjectBaseUrl } from "../../../utils/Constants";
import { API_ENDPOINTS } from "../../../utils/api-endpoints";
import http from "../../../utils/http";
import { globalSwalFunction } from "../../../utils/globalFunctions/globalSwal";
import { useDispatch } from "react-redux";
import { getAddress } from "../../../State/actions/AddressActions";
import { useFormik } from "formik";
import { signUpSchemas } from "../schemas";
import { axiosFunction } from "../../../utils/globalFunctions/axiosFunction";
import { getApiToken } from "../../../utils/get-token";
const SignUpDialogueModal = ({
  MobileNo,
  setMobileNo,
  isSignupDisabled,
  setIsSignupDisabled,
  setShowMobileNoComponent,
  setShowOtpVarificationComponent,
  showLoginOtpModalEventHandler,
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
  };
  /* --------------------// Formik Start //-------------------- */
  const handleFormSubmit = async (values, action) => {
    setIsSignupDisabled(true);
    const userData = {
      contactFirstName: values.customerFirstName,
      contactLastName: values.customerLastName,
      email: values.customerEmail,
      contactNo: MobileNo,
      country: Cookies.get("COUNTRY"),
      state: Cookies.get("STATE"),
      city: Cookies.get("CITY"),
      pincode: Cookies.get("pincode"),
    };
    try {
      const token = getApiToken();
      const res = await axiosFunction(
        token,
        `${ReactProjectBaseUrl}${API_ENDPOINTS.SIGN_UP}`,
        "POST",
        userData
      );
      if (res.data.status) {
        const { firstName, lastName, contactId, mobNo, email } =
          res.data.response;

        globalSwalFunction(
          "You have successfully signed up!",
          '<i class="fas fa-check-circle mb-1 text-success fs-5"></i>'
        );
        Cookies.set("CUSTOMER_NAME", `${firstName} ${lastName}`);
        Cookies.set("CUSTOMER_FIRST_NAME", firstName.split(" ")[0]);
        Cookies.set("CUSTOMER_LAST_NAME", lastName || firstName.split(" ")[1]);
        Cookies.set("ID", contactId);
        Cookies.set("CONTACT_NUMBER", mobNo);
        Cookies.set("CONTACT_EMAIL", email);
        setShowMobileNoComponent(true);
        setShowOtpVarificationComponent(false);
        showLoginOtpModalEventHandler("close");
        setMobileNo("");
        setIsSignupDisabled(false);
        dispatch(getAddress(contactId));
      } else {
        globalSwalFunction(
          "Something Went Wrong!",
          '<i class="fas fa-times-circle text-danger fs-5"></i>'
        );
        setIsSignupDisabled(false);
      }
    } catch (error) {
      console.error(error);
      setIsSignupDisabled(false);
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchemas,
      onSubmit: handleFormSubmit,
    });
  /* --------------------// Formik End //-------------------- */
  return (
    <form className="form-container">
      <div className="d-flex flex-column signup_modal_container">
        <div className="d-flex">
          <h1 className="fs-5 fw-bolder lh-base welcomeBack text-center">
            Welcome to VasyERP
          </h1>
        </div>
        <div className="d-flex justify-content-center">
          <img src="images/signup_modal.svg" alt="sign_up" />
        </div>
      </div>
      <div className="d-flex">
        <h1 className="fs-8 fw-bolder lh-base text-center create_account_text">
          Create Account
        </h1>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div className="form-outline">
            {/* first name */}
            <InputComponent
              id={"customerFirstName"}
              type="text"
              placeholder="First Name*"
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              tabIndex={1}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-outline">
            {/* last name */}
            <InputComponent
              id={"customerLastName"}
              type="text"
              placeholder="Last Name"
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              tabIndex={2}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      </div>

      <div className="form-outline mb-4">
        {/* email */}
        <InputComponent
          id={"customerEmail"}
          type="email"
          placeholder="Email ID (Optional)"
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          tabIndex={3}
          errors={errors}
          touched={touched}
        />
      </div>

      <div className="form-outline mb-4">
        <InputComponent
          id={"MobileNo"}
          type="tel"
          placeholder="Mobile Number"
          values={{ MobileNo: MobileNo }}
          disabled
          readOnly
        />
      </div>
      <div className="signupbtn_container">
        <input
          type="button"
          className="accountCredPopupBtn mx-auto"
          onClick={handleSubmit}
          value="Sign Up"
          tabIndex={4}
          disabled={isSignupDisabled}
        />
      </div>
    </form>
  );
};

export default SignUpDialogueModal;
