import  {ActionTypes}  from "../constants/ActionTypes";
const intialState = [];

export const topCategoryReducers = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOP_CATEGORIES:
      return payload;
    default:
      return state;
  }
};