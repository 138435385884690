import { ActionTypes } from "../constants/ActionTypes";
const intialState = [];

export const faviconLoaderReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.GET_FAVICON_LOADER:
      return payload;
    default:
      return state;
  }
};
