import useResponsive from "../../hooks/useResponsive";
import { useSelector } from "react-redux";
const FallbackSpinner = () => {
  const isMobile = useResponsive();
  const { faviconLoader } = useSelector((state) => state);
  let loaderSrc = faviconLoader.filter(
    (data) => data.utilityDriveType === "loader"
  );

  return (
    <div
      style={{
        position: "fixed",
        top: isMobile ? "50%" : "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#var(--white)",
        zIndex: 99999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <ReactLoading
        type="bars"
        color={`var(--primary-color)`}
        height={80}
        width={80}
      /> */}
      <img
        style={{ height: "50px", width: "50px" }}
        src={loaderSrc[0]?.signedSrc || "/images/preloader.gif"}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/preloader.gif";
        }}
        alt="preloader"
      />
    </div>
  );
};

export default FallbackSpinner;
