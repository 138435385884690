import Cookies from "js-cookie";
import React from "react";
import ReactTooltip from "react-tooltip";
import { locationSvg } from "../../../../utils/Constants";

const LocationMenu = ({ setShowGeoLoacationModalComponent }) => {
  const showGeoLocationModalEventHandler = () => {
    setShowGeoLoacationModalComponent(false);
  };
  return (
    <a
      className="locationMenu d-flex align-items-center"
      data-htmlfor="happyFace"
      data-iscapture="true"
      data-tip={
        Cookies.get("label") ? Cookies.get("label") : "Select Location"
      }
      onClick={() => showGeoLocationModalEventHandler()}
      style={{ position: "relative" }}
    >
      <div className="col-sm-4 px-2 locationIcon" dangerouslySetInnerHTML={{ __html: locationSvg }}>
        
      </div>
      <div
        className="detail locationDetail col-sm-8 px-0 text-start"
        style={{ marginRight: "0px", position: "relative" }}
      >
        {/* <small className="m-0 pe-sm-2">Delivery in 11 minutes</small> */}
        <span id="location" style={{ position: "relative", zIndex: "9999" }}>
          {Cookies.get("label") ? Cookies.get("label") : "Select Location"}
        </span>
      </div>
      <i
        class="fa fa-angle-down"
        aria-hidden="true"
        style={{ right: "-13px", position: "absolute", zIndex: "0" }}
      ></i>
      <ReactTooltip type="dark" effect="solid" />
    </a>
  );
};

export default LocationMenu;
