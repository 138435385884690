import { ActionTypes } from "../constants/ActionTypes";
const intialState = [];
const orderDetatailsInitialState = {};

export const orderListReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_SALES_LIST:
      return payload;

    case ActionTypes.RESET_ORDER_LIST:
      return payload;
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_SALES_DETAILS:
      return payload;
    default:
      return state;
  }
};

export const orderCancelReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CANCEL_ORDER:
      return payload;
    default:
      return state;
  }
};

export const orderTrackingTrailReducer = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.GET_SALES_TRACKING_TRAIL:
      return payload;
    default:
      return state;
  }
};
