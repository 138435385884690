import { ReactProjectBaseUrl } from "../../utils/Constants";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { getApiToken } from "../../utils/get-token";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { ActionTypes } from "../constants/ActionTypes";


export const getAds = () =>  async ( dispatch ) => {
  const token = getApiToken() 
  const response = await axiosFunction(token,`${ReactProjectBaseUrl}${API_ENDPOINTS.SET_ADVERTISEMENT}`,"GET")
  const adsResponse = response.data;
  let adsList = [];

  if (adsResponse) {
    if (adsResponse.status) {
        adsList = adsResponse.response
    }
  }
  
  dispatch({ type : ActionTypes.SET_ADVERTISEMENT , payload : adsList});
};