import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  NavLink,
  useParams,
  useLocation,
} from "react-router-dom";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import Typesense from "typesense";
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter";
import { Autocomplete } from "../AutoComplete";
import { ProductItem } from "../ProductItem";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { selectedProduct } from "../../State/actions/productActions";
import { logout } from "../../utils/logout";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_APP_KEY,
  TYPESENSE_CREDENTIALS,
} from "../../utils/Constants";
import { NavbarMobileContainer } from "./NavbarMobileContainer";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_APP_KEY);
const NavbarMobile = ({
  ShowSideMenuOnMobileView,
  setShowSideMenuOnMobileView,
  setShowLoginOtpModalComponent,
  showOverlay,
  setshowOverlay,
  showMobileSearch,
  setshowMobileSearch,
}) => {
  const enableWishlist = useSelector((state) => state.enableWishlist);
  const algoliaIndex = useSelector((state) => state.algoliaIndex);
  const { logo } = useSelector((state) => state);
  const cmspageflagData = useSelector(
    (state) => state.companyDetails.response?.cmspageflag
  );

  const dispatch = useDispatch();

  const setSelectedProductEventHandler = (productid, id) => {
    if (productid) {
      dispatch(selectedProduct(productid, id));
      navigate("/productView/" + productid);
      setshowOverlay(false);
    }
  };

  const cartItems = useSelector((state) => state.cartItems);

  const [Counter, setCounter] = useState(0);

  useEffect(() => {
    let qty = 0;
    for (const cartItem of cartItems) {
      qty += cartItem.quantity;
    }
    setCounter(qty);
  }, [cartItems]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const showMobileSearchEventHandler = (e) => {
    e.stopPropagation();
    if (!Cookies.get("label")) {
      globalSwalFunction(
        "Specify location to proceed further!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
      return;
    }

    setShowSideMenuOnMobileView(false);
    setShowLoginOtpModalComponent(false);
    setshowOverlay(true);
    setShowSideMenuOnMobileView(false);

    if (showMobileSearch) {
      setshowMobileSearch(false);
    } else {
      setshowOverlay(true);
      setshowMobileSearch(true);
    }
  };

  const hideAllModals = () => {
    setshowOverlay(false);
    setShowSideMenuOnMobileView(false);
    setshowMobileSearch(false);
  };
  const categories = () => {
    setshowOverlay(false);
    setShowSideMenuOnMobileView(false);
    setshowMobileSearch(false);
    setShowSideMenuOnMobileView(false);
  };
  const checkWeatherLoggedOrNot = (type) => {
    setShowSideMenuOnMobileView(false);
    setshowOverlay(false);
    setshowMobileSearch(false);
    if (Cookies.get("ID") && type === "myprofile") {
      navigate("../" + type, { replace: true });
    } else if (type === "myCart") {
      if (cartItems.length > 0) {
        navigate("../" + type, { replace: true });
      } else {
        navigate("../" + type, { replace: true });
        globalSwalFunction(
          "Cart is empty!",
          '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
        );
      }
    } else {
      globalSwalFunction(
        "Please login first!",
        '<i class="fas fa-exclamation-circle mb-1 text-warning fs-5"></i>'
      );
      setShowLoginOtpModalComponent(true);
    }
  };
  //
  const logoutEventHandler = () => {
    const result = logout();
    if (result) {
      navigate("../", { replace: true });
      window.location.reload(true);
    }
  };

  const search_response_adapter = (result) =>
    new SearchResponseAdapter(
      result,
      { params: {} },
      { geoLocationField: "_geoloc" }
    );

  const typesense_client = () =>
    new Typesense.Client({
      apiKey: TYPESENSE_CREDENTIALS.apiKey,
      nodes: [
        {
          host: TYPESENSE_CREDENTIALS.host,
          port: TYPESENSE_CREDENTIALS.port,
          protocol: TYPESENSE_CREDENTIALS.protocol,
        },
      ],
      connectionTimeoutSeconds: 2,
    });

  const client = typesense_client();
  return (
    <NavbarMobileContainer>
      {showOverlay && (
        <div
          id="cd-shadow-layer"
          className="is-visible"
          onClick={() => hideAllModals()}
        />
      )}
      <ul className="navbar-list">
        <li id="hamburger_container">
          <div className="mobilesidemenubar">
            <ul
              className={
                `menuLinks ` + (ShowSideMenuOnMobileView ? `active` : ``)
              }
            >
              <li style={{ marginTop: "20px" }}>
                <NavLink to="/" activeClassName="active">
                  Home
                </NavLink>
              </li>
              {cmspageflagData && cmspageflagData[1].ContactUs === 1 && (
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    Contact Us
                  </NavLink>
                </li>
              )}
              {cmspageflagData && cmspageflagData[0].AboutUs === 1 && (
                <li>
                  <NavLink to="/about" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
              )}
              <li hidden={Cookies.get("ID") ? false : true}>
                <NavLink to="/myprofile" activeClassName="active">
                  My Profile
                </NavLink>
              </li>
              {enableWishlist ? (
                <li hidden={Cookies.get("ID") ? false : true}>
                  <NavLink to="/mywishlist" activeClassName="active">
                    Wishlist
                  </NavLink>
                </li>
              ) : null}

              <li hidden={Cookies.get("ID") ? false : true}>
                <NavLink to="/orders" activeClassName="active">
                  Orders
                </NavLink>
              </li>
              {cmspageflagData &&
                cmspageflagData[3].TermsAndCondition === 1 && (
                  <li>
                    <NavLink to="/terms" activeClassName="active">
                      Terms &amp; Condition
                    </NavLink>
                  </li>
                )}
              {cmspageflagData && cmspageflagData[2].privacyPolicy === 1 && (
                <li>
                  <NavLink to="/privacy" activeClassName="active">
                    Privacy
                  </NavLink>
                </li>
              )}
              {cmspageflagData && cmspageflagData[4].ReturnPolicy === 1 && (
                <li>
                  <NavLink to="/return-policy" activeClassName="active">
                    Return Policy
                  </NavLink>
                </li>
              )}
              <li
                hidden={Cookies.get("ID") ? false : true}
                activeClassName="active"
              >
                <a onClick={() => logoutEventHandler()}>Logout</a>
              </li>
            </ul>
          </div>
        </li>
        <li className={`navmenu ${pathname === "/categories" ? "active" : ""}`}>
          <Link to="/categories" onClick={categories}>
            <i className="material-icons">widgets</i>
            <span className="icon-name">Categories</span>
          </Link>
        </li>
        <li className={`navmenu ${pathname === "/myprofile" ? "active" : ""}`}>
          <a onClick={() => checkWeatherLoggedOrNot("myprofile")}>
            <i
              className="fas fa-user-circle material-icons"
              aria-hidden="true"
            ></i>
            <span className="icon-name">Profile</span>
          </a>
        </li>
        <li
          id="cart_menu_container"
          className={`navmenu ${pathname === "/myCart" ? "active" : ""}`}
        >
          <a onClick={() => checkWeatherLoggedOrNot("myCart")}>
            <i className="material-icons">shopping_cart</i>
            <span className="icon-name">Cart</span>
            <div className="cart_item_count">
              <h3>{Counter}</h3>
            </div>
          </a>
        </li>
        <li>
          <a onClick={(e) => showMobileSearchEventHandler(e)}>
            <i className="material-icons">search</i>
            <span className="icon-name">Search</span>
          </a>
          {Cookies.get("label") && (
            <form className="mobileNavbarSearch d-flex" action="">
              <div className="search-box">
                <div className="item search right col-sm-5" tabIndex="0">
                  <Autocomplete
                    openOnFocus={false}
                    placeholder="Search Product"
                    navigator
                    getSources={({ query }) => [
                      {
                        sourceId: "products",
                        getItems: () =>
                          client
                            .collections(algoliaIndex)
                            .documents()
                            .search({
                              q: query,
                              query_by: "product_variant_name,item_code",
                            })
                            .then((result) => {
                              return search_response_adapter(result).adapt()
                                .hits;
                            }),

                        templates: {
                          item({ item, components }) {
                            return (
                              <ProductItem
                                hit={item}
                                components={components}
                                setSelectedProductEventHandler={
                                  setSelectedProductEventHandler
                                }
                              />
                            );
                          },
                          noResults() {
                            return "No results.";
                          },
                        },
                        getItemInputValue({ item }) {
                          return item.name;
                        },
                        onSelect({ item }) {
                          setSelectedProductEventHandler(
                            item.productId,
                            Number(item.objectID)
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </form>
          )}
        </li>
      </ul>
    </NavbarMobileContainer>
  );
};

export default NavbarMobile;
