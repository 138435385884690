import axios from "axios";
export const axiosFunction = async (token,url, method, body = null) => {
  if (token === undefined) return;
  const response = await axios({
    url,
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      API_TOKEN: token,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },

    data: method === "POST" ? body : null,
    params: method === "GET" ? body : null,
  });

  return response;
};
