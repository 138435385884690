import styled from "styled-components";

export const NewSideBarCartItemContainer = styled.div`
  /* background-color: red; */
  margin-top: 24px;
  .cart-item-parent {
    display: flex;
    gap: 5px;
    .cartItem-image-parent {
      max-width: 100px;
      max-height: 100px;
      min-width: 100px;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid #70707075 1px;
      border-radius: 10px;
      cursor: pointer;
      .itemImg {
        max-width: 85px;
        max-height: 85px;
      }
    }
    .itemName-row-container {
      margin-right: auto;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 10px;
      width: 100%;

      /* justify-content: space-around;  */
      /* justify-content: center;  */
      gap: 5px;

      .item-name-and-remove-item {
        width: 100%;
        justify-content: space-between;
        display: flex;
        .itemName {
          max-width: 181.2px;
          color: ${({ isOutOfStock }) =>
            !isOutOfStock ? "#707070" : "#272626"};
          font-size: 14px;
          font-weight: 700;

          display: -webkit-box;
          -webkit-line-clamp: 2; /* Limit to 2 lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
          cursor: pointer;
        }
      }

      .item-variant-and-Stock {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: auto;

        .item-variant {
          /* display: block; */
          /* border: solid #707070 1.8px; */
          align-items: center;
          /* padding: 1.5px 13px 1px 13px; */
          border-radius: 8px;
          color: #707070;
          font-size: 12px;
          font-weight: 700;

          padding: 0 45px 0 0;
          cursor: pointer;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* Limit to 2 lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
        }

        .stock-text {
          font-size: 14px;
          font-weight: 700;
        }

        .isActive {
          color: #ff0c0c;
        }
      }
      .mrp-parent {
        .mrp-text {
          color: #272626;
          font-size: 14px;
          font-weight: 600;

          color: ${({ isOutOfStock }) =>
            !isOutOfStock ? "#707070" : "#272626"};
        }
      }
    }

    .mrp-parent-and-qty-button {
      display: flex;
      justify-content: space-between;
      .mrp-parent {
        margin-right: auto;
      }
      .quantity-button-parent {
        display: flex;
        align-items: center;
        background-color: ${({ isOutOfStock }) =>
          !isOutOfStock ? "#707070" : "#414141"};
        /* height: 35.16px; */
        height: 28px;
        border-radius: 10px;
      }

      .remove-item-button,
      .add-item-button {
        height: 28px;
        padding: 4px 6px;
        display: flex;
        align-items: center;
        font-size: 22px;

        border: none;
        background-color: ${({ isOutOfStock }) => {
          console.log("isOutOfStock inner", isOutOfStock);
          return !isOutOfStock ? "#707070" : "#414141";
        }};
        cursor: ${({ isOutOfStock }) =>
          !isOutOfStock ? "not-allowed" : "pointer"};
        color: white;
        span {
          display: block;
        }
      }

      .remove-item-button {
        border-radius: 5px 0 0 5px;
        font-size: 42px;
        padding-bottom: 10px;
      }
      .add-item-button {
        font-weight: 800;
        font-size: 25px;
        padding-bottom: 8px;
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .out-of-stock {
    color: #ff0c0c;
  }
  .in-stock {
    color: #3e6002;
  }
`;
