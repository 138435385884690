import React, { useEffect, useState } from "react";
import NavbarLeftSection from "./NavbarLeftSection";
import NavbarSearchSection from "./NavbarSearchSection";
import NavbarRightSection from "./NavbarRightSection";
import NavbarMobile from "./NavbarMobile";
import SideBarFloatingBtnComponent from "../sidebarCart/SideBarFloatingBtnComponent";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import NavBarMegaMenu from "./NavBarMegaMenu";
import useResponsive from "../../hooks/useResponsive";
import UserMenu from "./NavbarRightChildren/UserDropDownChildren/UserMenu";
import { getBranchToken, isGeofenchingEnabled } from "../../utils/get-token";

const Navbar = ({ showHideLoginForm, setshowHideLoginForm }) => {
  const isMobile = useResponsive();
  const geoLocationFlag = useSelector((state) => state.geoLocationFlag);

  const [showGeoLoacationModalComponent, setShowGeoLoacationModalComponent] =
    useState(Cookies.get("GeoLocationFlag"));
  const [ShowLoginOtpModalComponent, setShowLoginOtpModalComponent] =
    useState(false);
  const [ShowSideMenuOnMobileView, setShowSideMenuOnMobileView] =
    useState(false);
    const [showOverlay, setshowOverlay] = useState(false);
    const [showMobileSearch, setshowMobileSearch] = useState(false);

    const ShowSideMenuOnMobileViewEventHandler = () => {
      setshowOverlay(false);
      setshowMobileSearch(false);
      setShowLoginOtpModalComponent(false);
      if (ShowSideMenuOnMobileView) {
        setShowSideMenuOnMobileView(false);
        setshowOverlay(false);
      } else {
        setShowSideMenuOnMobileView(true);
        setshowOverlay(true);
      }
    };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      // When the user scrolls the page, execute myFunction
      window.onscroll = function () {
        myFunction();
      };

      // Get the header
      var header = document.getElementsByClassName("headerNavbar")[0];

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
      function myFunction() {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (showHideLoginForm) {
      setShowLoginOtpModalComponent(true);
    } else {
      setShowLoginOtpModalComponent(false);
    }
  }, [showHideLoginForm]);

  useEffect(() => {
    if (ShowLoginOtpModalComponent || ShowSideMenuOnMobileView) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [ShowLoginOtpModalComponent, ShowSideMenuOnMobileView]);

  useEffect(()=>{
    if(isGeofenchingEnabled() == 1 && getBranchToken() === undefined){
      setShowGeoLoacationModalComponent(false)
    } else if(isGeofenchingEnabled() == 0 && getBranchToken() !== undefined){
      Cookies.remove("BRANCH_TOKEN")
    }
  },[])

  // Memoize Components
  const MemoizedNavbarLeftSection = React.memo(NavbarLeftSection);
  const MemoizedNavbarSearchSection = React.memo(NavbarSearchSection);
  const MemoizedNavbarRightSection = React.memo(NavbarRightSection);
  const MemoizedSideBarFloatingBtnComponent = React.memo(
    SideBarFloatingBtnComponent
  );
  const MemoizedUserMenu = React.memo(UserMenu);

  return (
    <>
      {/* <!-- Navbar --> */}
      <header>
        <nav className="navbar headerNavbar ">
          <MemoizedNavbarLeftSection
            setShowGeoLoacationModalComponent={
              setShowGeoLoacationModalComponent
            }
            ShowSideMenuOnMobileViewEventHandler={ShowSideMenuOnMobileViewEventHandler}
          />
          <MemoizedNavbarRightSection
            showGeoLoacationModalComponent={showGeoLoacationModalComponent}
            setShowGeoLoacationModalComponent={
              setShowGeoLoacationModalComponent
            }
            geoLocationFlag={geoLocationFlag}
            ShowLoginOtpModalComponent={ShowLoginOtpModalComponent}
            setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
            setshowHideLoginForm={setshowHideLoginForm}
            showHideLoginForm={showHideLoginForm}
          />
          <MemoizedNavbarSearchSection
            showGeoLoacationModalComponent={showGeoLoacationModalComponent}
            setShowGeoLoacationModalComponent={
              setShowGeoLoacationModalComponent
            }
            geoLocationFlag={geoLocationFlag}
          />
          <MemoizedUserMenu
            setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
          />
          <MemoizedSideBarFloatingBtnComponent
            setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
          />
        </nav>

        <NavBarMegaMenu />
        {/* <!-- mobile navbar --> */}
        {isMobile && (
          <NavbarMobile
            ShowSideMenuOnMobileView={ShowSideMenuOnMobileView}
            setShowSideMenuOnMobileView={setShowSideMenuOnMobileView}
            setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
            showOverlay={showOverlay}
            setshowOverlay={setshowOverlay}
            showMobileSearch={showMobileSearch}
            setshowMobileSearch={setshowMobileSearch}
          />
        )}
      </header>
      {/* <!-- Navbar --> */}
      {/* <MemoizedSideBarFloatingBtnComponent
        setShowLoginOtpModalComponent={setShowLoginOtpModalComponent}
      /> */}
    </>
  );
};

export default Navbar;
