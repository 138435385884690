import { ActionTypes } from "../constants/ActionTypes";


const initialState = [];

export const advertisementReducer = (state = initialState, {type, payload}) => {
    switch(type) {
        case ActionTypes.SET_ADVERTISEMENT:
            return payload;
        default:
            return state    
    }
}