import { ActionTypes } from "../constants/ActionTypes";
import http from "../../utils/http";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { getToken, getCompanyId, getBranchId, getApiToken } from "../../utils/get-token";
import { ReactProjectBaseUrl } from "../../utils/Constants";
import Cookies from "js-cookie";

export const SetUserMobileNo = (userMobileNo) => {
  return {
    type: ActionTypes.SET_USER_MOBILE_NO,
    payload: userMobileNo,
  };
};

export const fetchCompanyDetailsFromDomainName =
  (domainName) => async (dispatch) => {
    const token = getApiToken()
    http.interceptors.request.use(
      (config) => {
        config.headers['API_TOKEN'] =  token
        config.params = {
          ...config.params,
          domainName: domainName,
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const response = await http.get(
      ReactProjectBaseUrl +
        API_ENDPOINTS.FETCH_COMPANY_DETAILS_FORM_DOMAIN_NAME +
        "/" +
        domainName
    );
    const companyDetails = response.data;
    let decimalPoints = 0;
    let enableWishlist = 0;
    if (companyDetails.status) {
      Cookies.set("API_TOKEN", companyDetails.response.token);
      Cookies.set("companyDetails", JSON.stringify(companyDetails.response));
      Cookies.set("isGeofenchingEnabled", companyDetails?.response?.isGeofenchingEnabled)
      decimalPoints = companyDetails.response.decimalPoints;
      enableWishlist = companyDetails.response.enableWishlist;
    } else {
      decimalPoints = 0;
      enableWishlist = 0;
    }

    dispatch({
      type: ActionTypes.SET_LOGO,
      payload: companyDetails.response.logo,
    });
    dispatch({
      type: ActionTypes.SET_ALGOLIA_INDEX_NAME,
      payload: companyDetails.response.algoliaIndexName,
    });
    dispatch({
      type: ActionTypes.SET_IS_ONLINE_ORDER_CONFIRMATION_FLOW,
      payload: companyDetails.response.isOrderConfirmationFlowEnabled,
    });
    dispatch({
      type: ActionTypes.SET_COMPANY_DETAILS_FROM_DOMAIN,
      payload: response.data,
    });
    dispatch({ type: ActionTypes.SET_DECIMAL_POINTS, payload: decimalPoints });
    dispatch({ type: ActionTypes.ENABLE_WISHLIST, payload: enableWishlist });
  };

export const fetchThemeSettings = (settingsData) => async (dispatch) => {
  const themeSettings = settingsData;
  if (themeSettings) {
    Cookies.set("primary_color", themeSettings.primary_color);
    Cookies.set("secondary_color", themeSettings.secondary_color);
    document.documentElement.style.setProperty(
      "--ltn__primary-color",
      themeSettings.primaryColorDark
    );
    document.documentElement.style.setProperty(
      "--ltn__secondary-color",
      themeSettings.secondaryColorDark
    );
    document.documentElement.style.setProperty(
      "--primary__light_color",
      themeSettings.primaryColorLight
    );
    document.documentElement.style.setProperty(
      "--secondary__light_color",
      themeSettings.secondaryColorDark
    );
  }

  dispatch({ type: ActionTypes.SET_THEME_SETTINGS, payload: settingsData });
};

// Profile Details

export const fetchProfileDetails = (payload) => {
  return {
    type: ActionTypes.FETCH_PROFILE_DETAILS,
    payload,
  };
};
