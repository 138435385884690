import React, { useEffect, useMemo } from "react";
import SideBarCartItems from "./SideBarCartItems";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  CHECKOUT_ICON,
  CurrencySymbol,
  ReactProjectBaseUrl,
} from "../../utils/Constants";
import useAuth from "../../hooks/useAuth";
import { ClearCartEventHandler } from "../../utils/globalFunctions/cartFunctions";
import { realTimeStockUpdateFunc } from "../../utils/globalFunctions/realTimeStockUpdate";
import useSubTotalPrice from "../../hooks/useSubTotalPrice";
import PrimaryButton from "../PrimaryButton";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { defaultImage } from "../../utils/productUtils/ProductUtils";
import { globalSwalFunction } from "../../utils/globalFunctions/globalSwal";
import { ActionTypes } from "../../State/constants/ActionTypes";
import { NewSideBarCartContainer } from "./NewSideBarCartContainer";
import {
  CrossIcon,
  DeleteIcon,
  ShoppingBagsIcon,
} from "../../pages/checkout/icons/checkoutPageIcons";
import NewSideBarCartItem from "./NewSideBarCartItem";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../../utils/get-token";

const NewSideBarCart = ({
  isVisible,
  ShowHideSideBarCartComponentEventHandler,
  setShowLoginOtpModalComponent,
  setShowHideSideBarCartComponent,
  counter,
}) => {
  const { decimalPoints, cartItems } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useAuth hook for user authenticate or not...
  const { checkUserAuthenticateOrNot } = useAuth("sidebarCart");

  // Subtotalprice -- custom hook
  const subTotal = useSubTotalPrice();

  // Real Time Stock Update Function call.
  useEffect(() => {
    realTimeStockUpdateFunc(cartItems, decimalPoints, dispatch);
  }, []);

  const myCartNavigation = () => {
    const token =
      isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();
    let cartArray = cartItems.map((cartProductObj) => ({
      ...cartProductObj,
      subTotal: parseFloat(
        parseFloat(cartProductObj.price) * cartProductObj.quantity
      ),
    }));
    axiosFunction(
      token,
      `${ReactProjectBaseUrl}${API_ENDPOINTS.CART_LIST}`,
      "POST",
      {
        cartDTOs: cartArray,
      }
    ).then((res) => {
      const saveApiResponse = res.data.response.cartDTOs;
      const revampData = saveApiResponse.reduce((acc, item) => {
        const cartItem = cartItems.find(
          (productItem) =>
            productItem.productVarientId === item.productVarientId
        );

        if (!cartItem) {
          return acc;
        }

        const latestQuantity = Math.min(
          item.availableQuantity < 0 ? 0 : item.availableQuantity,
          cartItem.quantity
        );

        acc.push({
          productVarientId: item.productVarientId,
          price: item.sellingprice,
          quantity: Math.floor(latestQuantity),
          imgSrc: item.imgSrc || defaultImage,
          productName: item.name,
          productid: item.productId,
          isFromBuyNow: 0,
          availableQuantity: Math.floor(item.availableQuantity),
          variantName: item.variantName,
          activeOnline: item.activeOnline,
        });

        return acc;
      }, []);
      const compareCartProduct = cartItems.every((product) => {
        return revampData.some((revampProduct) => {
          return (
            revampProduct.availableQuantity >= product.quantity &&
            revampProduct.price === product.price &&
            revampProduct.activeOnline === product.activeOnline
          );
        });
      });
      if (compareCartProduct) {
        navigate("/mycart");
      } else {
        globalSwalFunction(
          "There are some changes in your cart",
          '<i class="fas fa-times-circle text-danger fs-5"></i>'
        ).then(() => {
          navigate("/mycart");
        });
      }
      dispatch({
        type: ActionTypes.ADD_TO_CART,
        payload: revampData,
      });
    });
    setShowHideSideBarCartComponent(false);
  };
  const MemoizedSideBarCartItems = React.memo(SideBarCartItems);

  // Memoized Link component
  const MemoizedLink = useMemo(
    () => (
      <Link to="/mycart" className="text-start col-sm-6 fs-6">
        <i className="fas fa-shopping-bag pe-2"></i>
        Items: ({cartItems.length})
      </Link>
    ),
    [cartItems.length]
  );
  const isOutOfStock = cartItems.some(
    (items) => items.availableQuantity <= 0 || items.activeOnline == 0
  );

  return (
    <NewSideBarCartContainer isOutOfStock={isOutOfStock}>
      <div
        id="cd-cart"
        className={
          isVisible ? "speed-in d-flex flex-column justify-content-between" : ""
        }
        style={{ padding: "0px" }}
      >
        <div className="sidebar-cart-parent">
          <div className="sidebar-cart-inner-parent">
            {/* header - start */}
            <div>
              <div className="sidebar-header">
                <div
                  className="close-icon-parent"
                  onClick={() =>
                    ShowHideSideBarCartComponentEventHandler(false)
                  }
                >
                  <CrossIcon />
                </div>
                <div className="no-of-items-and-clear-all">
                  <div className="no-of-items-icon-and-no-of-itemIn-digits">
                    <div className="no-of-items-icon">
                      <ShoppingBagsIcon
                        color="#414141"
                        width={20}
                        height={20}
                      />
                    </div>
                    <div className="no-of-itemIn-digits">Items: {counter}</div>
                  </div>
                  <div className="clear-all-button-parent">
                    {!!cartItems.length > 0 && (
                      <button
                        className="clear-all-button"
                        onClick={() => {
                          ClearCartEventHandler(
                            ShowHideSideBarCartComponentEventHandler,
                            dispatch
                          );
                        }}
                      >
                        {" "}
                        Clear All
                      </button>
                    )}
                  </div>
                </div>
                {cartItems.some(
                  (items) =>
                    items.availableQuantity <= 0 || items.activeOnline === 0
                ) && (
                  <p
                    style={{
                      color: "rgba(0, 0, 0, 0.447)",
                      fontSize: "15px",
                      border: "3px dashed rgba(255, 0, 0, 0.353)",
                      textAlign: "center",
                      padding: "5px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      margin: "18px 0 0 0 ",
                    }}
                  >
                    Remove unavailable item from the cart in order to proceed.
                  </p>
                )}
                <hr className="separator"></hr>
              </div>

              {/* Cart items listing - start */}
              {!!cartItems?.length > 0 ? (
                <div className="cartItems-listing-parent">
                  {/* cart items loop start */}
                  {cartItems.map((cartItem) => (
                    <NewSideBarCartItem
                      key={cartItem.productid}
                      cartItem={cartItem}
                      ShowHideSideBarCartComponentEventHandler={
                        ShowHideSideBarCartComponentEventHandler
                      }
                    />
                  ))}
                  <div
                    className="empty-space"
                    style={{ padding: "20px 0" }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/empty_cart.png"
                    className="d-flex mx-auto justify-content-center align-items-center emptyCartImage"
                    alt=""
                    style={{ minHeight: "350px", minWidth: "300px" }}
                  />
                </div>
              )}
            </div>
            {/* Cart items listing - end */}
            {/* footer - start */}
            {!!cartItems.length > 0 && (
              <div className="footer-parent">
                <hr className="separator-footer"></hr>
                <div className="total-text">
                  Total: {CurrencySymbol}
                  {parseFloat(subTotal).toFixed(2)}
                </div>
                <div className="view-cart-and-checkout-cart-button">
                  <button
                    className="view-cart-button footer-button"
                    onClick={() => myCartNavigation()}
                  >
                    View Cart
                  </button>
                  <button
                    className="checkout-cart-button footer-button"
                    disabled={isOutOfStock}
                    onClick={() => {
                      checkUserAuthenticateOrNot(
                        ShowHideSideBarCartComponentEventHandler,
                        setShowLoginOtpModalComponent
                      );
                      setShowHideSideBarCartComponent(false);
                    }}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </NewSideBarCartContainer>
  );
};

export default NewSideBarCart;
