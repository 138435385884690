import { ActionTypes } from "../constants/ActionTypes";
const intialState = [];

const bestSellingProductsList = [];

const trendingProductsList = [];

const defaultProductVo = {
  concat: "0-0",
  have_variants: 0,
  image: null,
  mrp: 0,
  product_count: 0,
  product_id: 0,
  product_name: "",
  product_varient_id: 0,
  profit: 0,
  quantity: 0,
  selling_price: 0,
  total_sales: 0,
};

const demoSelectedProduct = {
  productdetalils: [],
  productVarient: [],
  productImages: [],
};

const defaultMinMaxPriceRangeValue = {
  minprice: 0,
  maxprice: 5000,
};

export const bestSellingProductsReducer = (
  state = bestSellingProductsList,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_BESTSELLING_PRODUCTS:
      return payload;
    default:
      return state;
  }
};

export const trendingProductsReducer = (
  state = trendingProductsList,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_TRENDING_PRODUCTS:
      return payload;
    default:
      return state;
  }
};

export const productsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return payload;

    case ActionTypes.RESET_PRODUCTS:
      return payload;
    default:
      return state;
  }
};

export const totalProductReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOTAL_PRODUCT:
      return { ...state, products: payload };

    case ActionTypes.RESET_TOTAL_PRODUCTS:
      return { ...state, products: 0 };
    default:
      return state;
  }
};

export const selectedProductsReducer = (
  state = demoSelectedProduct,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return payload;

    case ActionTypes.RESET_SELECTED_PRODUCT:
      return demoSelectedProduct;

    default:
      return state;
  }
};

export const priceRangeReducer = (
  state = defaultMinMaxPriceRangeValue,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_MIN_MAX_PRICE_RANGE:
      return payload;
    default:
      return state;
  }
};

export const inclueOutOfStockReducer = (state = 0, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_INCLUE_OUT_OF_STOCK:
      return payload;
    default:
      return state;
  }
};
