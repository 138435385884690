import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import FallbackSpinner from "../components/LoadingSpinner/FallbackSpinner";
import Navbar from "../components/navbarComponent/Navbar";
import ErrorBoundry from "../pages/ErrorBoundry/ErrorBoundry";
import ReturnPolicyPage from "../pages/CmsPages/ReturnPolicy/ReturnPolicyPage";
import AccessDeniedPage from "../pages/AccessDeniedPage/AccessDeniedPage";

// import FooterComponent from "../components/footer/FooterComponent";
const FooterComponent = lazy(() =>
  import("../components/footer/FooterComponent")
);
const HomePage = lazy(() => import("../pages/HomePage"));
const ShopPage = lazy(() => import("../pages/ShopPage"));
const ProductViewPage = lazy(() =>
  import("../pages/productView/ProductViewPage")
);
const MyCartPage = lazy(() => import("../pages/mycart/MyCartPage"));
const NewMyCartPage = lazy(() => import("../pages/mycart/NewMyCartPage"));
const MyWishlistPage = lazy(() => import("../pages/mywishlist/MyWishlistPage"));
const CheckoutPage = lazy(() => import("../pages/checkout/CheckoutPage"));
const NewCheckoutPage = lazy(() => import("../pages/checkout/NewCheckoutPage"));
const Profile = lazy(() => import("../pages/profile/Profile"));

const MobileParentCategoryPage = lazy(() =>
  import("../pages/MobileParentCategory/MobileParentCategoryPage")
);
// const MyOrderPage = lazy(() => import("../pages/myOrder/MyOrderPage"));
const MyOrderPageNew = lazy(() => import("../pages/myOrder/MyOrderPageNew"));
const OrderDetailPageOld = lazy(() =>
  import("../pages/orderDetail/OrderDetailPage")
);
const OrderDetailPageNew = lazy(() =>
  import("../pages/orderDetail/OrderDetailPageNew")
);
const MobileSubCategoryComponent = lazy(() =>
  import("../pages/MobileSubCategory/MobileSubCategoryComponent")
);
const TermsAndConditions = lazy(() =>
  import("../pages/CmsPages/terms/TermsPage")
);
const PrivacyPage = lazy(() => import("../pages/CmsPages/privacy/PrivacyPage"));
const GaterWayErrorPage = lazy(() =>
  import("../pages/GateWayErrorPage/GaterWayErrorPage")
);
const ContactUsPage = lazy(() =>
  import("../pages/CmsPages/contact/ContactUsPage")
);
const AboutUsPage = lazy(() => import("../pages/CmsPages/about/AboutUsPage"));

const RoutesComponent = ({
  relatedDiv,
  setRelatedDiv,
  loadingOnProductQuickPage,
  setloadingOnProductQuickPage,
  showProductQuickViewModal,
  setShowProductQuickViewModal,
  showTrendingQuickView,
  showBestQuickView,
  showHideLoginForm,
  setshowHideLoginForm,
  triggerQuickViewModal,
}) => {
  const location = useLocation();
  const hideNavbarAndFooter = location.pathname === "/orderSuccess";

  const routes = [
    {
      path: "/",
      component: HomePage,
      props: {
        showProductQuickViewModal,
        showBestQuickView,
        showTrendingQuickView,
        triggerQuickViewModal,
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        setshowHideLoginForm,
        showHideLoginForm,
        relatedDiv,
        setRelatedDiv,
      },
    },
    {
      path: "/shop/:id",
      component: ShopPage,
      props: {
        showProductQuickViewModal,
        triggerQuickViewModal,
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        setshowHideLoginForm,
        showHideLoginForm,
        relatedDiv,
        setRelatedDiv,
      },
    },
    {
      path: "/productview/:id",
      component: ProductViewPage,
      props: {
        showProductQuickViewModal,
        showTrendingQuickView,
        showBestQuickView,
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        setshowHideLoginForm,
        showHideLoginForm,
        triggerQuickViewModal,
        setshowHideLoginForm,
        relatedDiv,
        setRelatedDiv,
      },
    },
    {
      path: "/mycart/*",
      component: NewMyCartPage,
      props: {
        showProductQuickViewModal,
        showBestQuickView,
        showTrendingQuickView,
        setshowHideLoginForm,
        showHideLoginForm,
        setRelatedDiv,
        triggerQuickViewModal,
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        relatedDiv,
        setRelatedDiv,
      },
    },
    {
      path: "/oldmycart/*",
      component: MyCartPage,
      props: {
        setshowHideLoginForm,
        showHideLoginForm,
      },
    },

    {
      path: "/mywishlist/*",
      component: MyWishlistPage,
      props: {
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        relatedDiv,
        setRelatedDiv,
        setshowHideLoginForm,
        showHideLoginForm,
        showProductQuickViewModal,
        triggerQuickViewModal,
      },
    },
    {
      path: "/oldcheckout/*",
      component: CheckoutPage,
    },
    {
      path: "/checkout/*",
      component: NewCheckoutPage,
    },
    {
      path: "/myprofile",
      component: Profile,
    },
    {
      path: "/categories",
      component: MobileParentCategoryPage,
    },
    {
      path: "/orders",
      // component: MyOrderPage,
      component: MyOrderPageNew,
    },
    {
      path: "/orderdetails",
      // component: OrderDetailPage,
      component: OrderDetailPageNew,
    },
    {
      path: "/orderDetailsold",
      component: OrderDetailPageOld,
    },

    {
      path: "/category/:id",
      component: MobileSubCategoryComponent,

      props: {
        showProductQuickViewModal,
        triggerQuickViewModal,
        setloadingOnProductQuickPage,
        loadingOnProductQuickPage,
        relatedDiv,
        setRelatedDiv,
        setshowHideLoginForm,
        showHideLoginForm,
      },
    },
    {
      path: "/terms",
      component: TermsAndConditions,
    },
    {
      path: "/privacy",
      component: PrivacyPage,
    },

    {
      path: "*",
      component: GaterWayErrorPage,
    },
    {
      path: "/contact",
      component: ContactUsPage,
    },
    {
      path: "/about",
      component: AboutUsPage,
    },
    {
      path: "/return-policy",
      component: ReturnPolicyPage,
    },

    {
      path: "/access-denied",
      component: AccessDeniedPage,
    },
  ];
  const MemoizedNavbar = React.memo(Navbar);
  const isCategoryPath = location.pathname.includes("/category/");
  return (
    <>
      {hideNavbarAndFooter ? null : (
        <MemoizedNavbar
          setshowHideLoginForm={setshowHideLoginForm}
          showHideLoginForm={showHideLoginForm}
        />
      )}
      <ErrorBoundry location={location.pathname}>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <route.component {...route.props} />
                </Suspense>
              }
            />
          ))}
        </Routes>
      </ErrorBoundry>
      <Suspense fallback={<FallbackSpinner />}>
        {!hideNavbarAndFooter && <FooterComponent />}
      </Suspense>
    </>
  );
};

export default RoutesComponent;
